import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-ui',
  templateUrl: './block-ui.component.html',
  styleUrls: ['./block-ui.component.css']
})
export class BlockUIComponent implements OnInit {
  blocked:boolean = true;
  
  constructor() { }

  ngOnInit(): void {
  }

}
