import { Routes } from '@angular/router';

import { DashboardComponent } from '../../dashboard/dashboard.component';
import { UserProfileComponent } from '../../user-profile/user-profile.component';
import { TableListComponent } from '../../table-list/table-list.component';
import { TypographyComponent } from '../../typography/typography.component';
import { IconsComponent } from '../../icons/icons.component';
import { MapsComponent } from '../../maps/maps.component';
import { NotificationsComponent } from '../../notifications/notifications.component';
import { UpgradeComponent } from '../../upgrade/upgrade.component';
import { DesignFormQmComponent } from 'app/management/management/design-form-qm/design-form-qm.component';
import { QuestionnaireComponent } from 'app/management/management/design-form-qm/questionnaire/questionnaire.component';
import { FormlistComponent } from 'app/management/management/design-form-qm/formlist/formlist.component';
import { MergeformComponent } from 'app/management/management/design-form-qm/mergeform/mergeform.component';
import { QuestionnaireMComponent } from 'app/management/management/design-form-qm/questionnaire-m/questionnaire-m.component';
import { ConfigLanguageComponent } from 'app/management/config-language/config-language.component';
import { FormDesignComponent } from 'app/management/management/design-form-qm/form-design/form-design.component';
import { LoginComponent } from 'app/login/login.component';
import { EmailcontentComponent } from 'app/management/management/design-form-qm/emailcontent/emailcontent.component';

export const AdminLayoutRoutes: Routes = [
    // {
    //   path: '',
    //   children: [ {
    //     path: 'dashboard',
    //     component: DashboardComponent
    // }]}, {
    // path: '',
    // children: [ {
    //   path: 'userprofile',
    //   component: UserProfileComponent
    // }]
    // }, {
    //   path: '',
    //   children: [ {
    //     path: 'icons',
    //     component: IconsComponent
    //     }]
    // }, {
    //     path: '',
    //     children: [ {
    //         path: 'notifications',
    //         component: NotificationsComponent
    //     }]
    // }, {
    //     path: '',
    //     children: [ {
    //         path: 'maps',
    //         component: MapsComponent
    //     }]
    // }, {
    //     path: '',
    //     children: [ {
    //         path: 'typography',
    //         component: TypographyComponent
    //     }]
    // }, {
    //     path: '',
    //     children: [ {
    //         path: 'upgrade',
    //         component: UpgradeComponent
    //     }]
    // }
    { path: 'dashboard', component: DashboardComponent },
    { path: 'user-profile', component: UserProfileComponent },
    { path: 'table-list', component: TableListComponent },
    { path: 'typography', component: TypographyComponent },
    { path: 'icons', component: IconsComponent },
    { path: 'maps', component: MapsComponent },
    { path: 'notifications', component: NotificationsComponent },
    { path: 'upgrade', component: UpgradeComponent },
    { path: 'designform', component: DesignFormQmComponent },
    { path: 'designform/:id', component: DesignFormQmComponent },
    // { path: 'showform', component: QuestionnaireComponent },
    // { path: 'showform/:token', component: QuestionnaireComponent },
    // { path: 'showformm', component: QuestionnaireMComponent },
    // { path: 'showformm/:token', component: QuestionnaireMComponent },
    { path: 'formlist', component: FormlistComponent },
    { path: 'formlist/:id', component: FormlistComponent },
    { path: 'mergeform', component: MergeformComponent },
    { path: 'configlang', component: ConfigLanguageComponent },
    { path: 'formdesign', component: FormDesignComponent },
    { path: 'formdesign/:id', component: FormDesignComponent },
    { path: 'emailcontent', component: EmailcontentComponent, },
    
    // { path: 'login', component: LoginComponent },

];
