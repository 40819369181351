import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { HttpService } from './http/http.service';
import { HttpCacheService } from './http/http-cache.service';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';
import { AuthInterceptor } from './http/auth-interceptor';
import { DisableApiResponseInterceptor } from './http/disable-api-response-interceptor';
import { SharedModule } from 'app/shared/shared.module';
import { AppModule } from 'app/app.module';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, SharedModule],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    HttpCacheService,
    AuthInterceptor,
    ApiPrefixInterceptor,
    DisableApiResponseInterceptor,
    CacheInterceptor,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: CoreModule,
      providers: [DynamicDialogRef]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
