import { Component, NgZone, ViewChild, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import * as _ from 'lodash';
import { speedDialFabAnimations } from './animations';
import { FormBuilder } from '@angular/forms';
import { FormQuestion, Question, TypeQuestion } from 'app/shared/model/form-question';
import { QualityManagementService } from 'app/shared/service/quality-management-service';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-design-form-qm',
  templateUrl: './design-form-qm.component.html',
  styleUrls: ['./design-form-qm.component.css'],
  animations: speedDialFabAnimations
})
export class DesignFormQmComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  typeQuestionlist: TypeQuestion[] = [];
  typeQuestion: TypeQuestion;
  fieldArray: FormQuestion[] = [];
  formQuestion: FormQuestion;
  question: Question;
  formtype: any;
  answerRow: string;
  answerRowIndex: number;
  showPreview: boolean;
  showPreviewM: boolean;
  formId: number;
  fabButtons = [
    {
      icon: 'dock',
      value: 'previewM'
    },
    {
      icon: 'airplay',
      value: 'preview'
    },
    {
      icon: 'save',
      value: 'save'
    },
    {
      icon: 'add',
      value: 'new'
    }
  ];
  buttons = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  groupmail = [];
  fabTogglerState = 'inactive';
  constructor(private _ngZone: NgZone,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private qualityManagementService: QualityManagementService,
    public router: Router
  ) {
    this.route.params.subscribe(data => {
      
      if (data.id) {
        this.qualityManagementService.getFormById(data.id, 'QualityForm').subscribe((res: any) => {
          // 
          this.getNewDocument(res[0])
        });
      } else {
        this.qualityManagementService.getFormList('QualityForm').subscribe((res: any) => {
          this.formId = Math.max.apply(Math, res.map(function (o) { return o.id + 1; }))
        });
      }
    });
  }

  ngOnInit(): void {


    this.typeQuestion = new TypeQuestion();
    const questionType = {
      id: 1,
      typeQuestionName: 'หลายตัวเลือก',
    }
    const questionType1 = {
      id: 2,
      typeQuestionName: 'ตารางกริตช่องทำเครื่องหมาย',
    }
    const questionType2 = {
      id: 3,
      typeQuestionName: 'ตารางกริตหลายช่องทำเครื่องหมาย',
    }
    const questionType3 = {
      id: 4,
      typeQuestionName: 'ช่องทำเครื่องหมาย',
    }
    const questionType4 = {
      id: 5,
      typeQuestionName: 'กรอกข้อมูล',
    }
    const questionType5 = {
      id: 6,
      typeQuestionName: 'จับกลุ่ม',
    }
    this.typeQuestionlist.push(questionType, questionType1, questionType2, questionType3, questionType4,questionType5);

    this.getNewDocument();
    // this.getNewQuestion('Add');
  }
  getInput(index: any, typeId: any) {
    // this.getNewQuestion('Change');
    this.formQuestion.question[index].answerRow.splice(0, this.formQuestion.question[index].answerRow.length);
    this.formQuestion.question[index].answerColumn.splice(0, this.formQuestion.question[index].answerColumn.length);
    if (typeId === '1' || typeId === '4' || typeId === '5'|| typeId === '6') {
      this.getInputRow(index);
    } else if (typeId === '2' || typeId === '3') {
      this.getInputRow(index);
      this.getInputCol(index);
    } else {

    }
  }

  getInputRow(index: any) {
    // var group = 'Group'.concat(index.toString());
    const ansRow = {
      id: this.formQuestion.question[index].answerRow.length + 1,
      ansRow: '',
      ansPoint: 0
    }
    if(this.formQuestion.question[index].typeQuestionId == 6) 
    {
      Object.assign(ansRow, { group : true});
    }
    this.formQuestion.question[index].answerRow.push(ansRow);
    // 
  }

  getInputCol(index: any) {
    const ansCol = {
      id: this.formQuestion.question[index].answerColumn.length + 1,
      ansCol: '',
      ansPoint: 0
    }
    this.formQuestion.question[index].answerColumn.push(ansCol)
    // 
  }
  getNewDocument(res?: any) {
    if (res) {
      this.formQuestion = new FormQuestion();
      this.formQuestion.id = res.id;
      this.formQuestion.formName = res.formName;
      this.formQuestion.formtDetail = res.formtDetail;
      this.formQuestion.question = JSON.parse(res.question);
      this.formQuestion.language = res.language
      // 
    } else {
      this.formQuestion = new FormQuestion();
      this.formQuestion.formName = undefined;
      this.formQuestion.formtDetail = undefined;
      this.formQuestion.question = [];
      this.getNewQuestion('Add');
    }
  }
  getNewQuestion(txt: any) {
    this.question = new Question();

    this.question.answerRow = [];
    this.question.answerColumn = [];
    this.question.questionName = undefined;
    this.question.typeQuestionId = this.typeQuestion.id;
    this.formQuestion.question.push(this.question);
  }


  addFieldValueX(index: any, indexChild: any, typeQues: string, typeId: any) {
    if (typeQues === 'row') {
      const lastFieldArray = this.formQuestion.question[index].answerRow[this.formQuestion.question[index].answerRow.length - 1];
      if ((this.formQuestion.question[index].answerRow.length - 1) === indexChild && lastFieldArray.ansRow !== '') {
        this.getInputRow(index);
      }
    } else {
      const lastFieldArray = this.formQuestion.question[index].answerColumn[this.formQuestion.question[index].answerColumn.length - 1];
      if ((this.formQuestion.question[index].answerColumn.length - 1) === indexChild && lastFieldArray.ansCol !== '') {
        this.getInputCol(index);
      }
    }
  }

  deleteFieldValueX(index, indexChild: any, typeQues: any) {
    // 
    if (typeQues === 'row') {
      this.formQuestion.question[index].answerRow.splice(indexChild, 1);
      this.formQuestion.question[index].answerRow.map((data, i) => {
        data.id = i + 1;
      })
    } else if (typeQues === 'col') {
      this.formQuestion.question[index].answerColumn.splice(indexChild, 1);
      this.formQuestion.question[index].answerColumn.map((data, i) => {
        data.id = i + 1;
      })
    } else {
      this.formQuestion.question.splice(index, 1);
      // 
    }
  }

  selectFabBtn(val: any) {
    if (val === 'new') {
      this.getNewQuestion('Add');
    } else if (val === 'save') {
      this.onSave();
    } else if (val === 'preview') {
      this.showPreview = true
    } else if (val === 'previewM') {
      this.showPreviewM = true
    }
  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  sidenavClosed() {
    const abc = this.formQuestion.question.filter(x => x.typeQuestionId == 5).map(y => {
      return y.answerRow[0].ansRow = '';
    });
    
  }
  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  mapData() {
    // 
    const data = {
      id: !this.formId ? this.formQuestion.id : this.formId,
      formName: this.formQuestion.formName,
      formtDetail: this.formQuestion.formtDetail,
      language: this.formQuestion.language,
      question: JSON.stringify(this.formQuestion.question),
      suggestion: '',
      email: this.formQuestion.email = this.groupmail.map(elem => {
        return elem.name;
      }).join(','),
      isActive: 1
    }
    return data
  }

  onSave() {
    swal({
      title: 'Are you sure?',
      text: 'You will save form!',
      showCancelButton: true,
      confirmButtonText: 'Save',
    }).then((result: any) => {
      const Data = this.mapData();
      if (this.formId) {
        const saveData = {
          tableName: 'QualityForm',
          Data: Data
        }
        this.qualityManagementService.saveData(saveData).subscribe((res: any) => {
          alert('Save complete!!');
          this.router.navigate(['/formlist']);
        });
      } else {
        const saveData = {
          tableName: 'QualityForm',
          Datas: [Data],
          Keywords: ['id']
        }
        this.qualityManagementService.listsaveClearOld(saveData).subscribe((res: any) => {
          alert('Edit complete!!');
          this.router.navigate(['/formlist']);
        });
      }
    });
  }
  testsendmail() {
    const val = {
      formId: '3',
      CustId: 'A0074',
    }

    this.qualityManagementService.sendMail().subscribe((res: any) => {
      // 
      // this.sendmail(res.key);
      // this.qualityManagementService.verifyOtp(res.key).subscribe((res1: any) => {
      //   
      // });
    });
  }



  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // 

    if ((value || '').trim()) {
      this.groupmail.push({ name: value.trim() });
    }

    if (input) {
      input.value = '';
    }
  }

  remove(email: any): void {
    const index = this.groupmail.indexOf(email);

    if (index >= 0) {
      this.groupmail.splice(index, 1);
    }
  }

  // sendmail(token: any) {
  //   this.qualityManagementService.sendMail(token).subscribe((res: any) => {
  //     
  //   });
  // }
}
