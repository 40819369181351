import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

const routes = {
  quote: (c: RandomQuoteContext) => `/jokes/random?category=${c.category}`
};

export interface RandomQuoteContext {
  // The quote's category: 'dev', 'explicit'...
  category: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  getDepartment() {
    return this.httpClient.get(environment.userUrl + '/api/departments');
  }

  getUserList() {
    return this.httpClient.get(environment.userUrl + '/api/Users');
  }

  getUserByID(url: string, body?: any) {
    return this.httpClient.get(environment.userUrl + url, body);
  }

  getKeyAccessMobile(url: string, body?: any) {
    return this.httpClient.get(environment.userUrl + url, body);
  }

  postCreateUser(body?: any) {
    return this.httpClient.post(environment.userUrl + '/api/Users/', body);
  }

  putEditUser(body?: any) {
    return this.httpClient.put(environment.userUrl + '/Users/', body);
  }

  postChangePassword(body?: any) {
    return this.httpClient.post(environment.userUrl + '/api/Account/ChangePassword/', body);
  }

  getPersonProfile() {
    return this.httpClient.get(environment.userUrl + '/api/Persons/');
  }

  getPersonByID(id?: any) {
    return this.httpClient.auth().get(environment.userUrl + '/api/persons/' + id);
  }

  putPersonProfile(body?: any) {
    return this.httpClient.put(environment.userUrl + '/api/Persons/', body);
  }

  putResetPassword(body?: any) {
    return this.httpClient.put(environment.userUrl + '/api/Users/reset/', body);
  }

  putPersonProfileByID(body?: any) {
    return this.httpClient.put(environment.userUrl + '/api/Persons/person/', body);
  }

  postPersonRegister(body?: any) {
    return this.httpClient.auth().post(environment.userUrl + '/api/persons/', body);
  }

  getPersonList() {
    return this.httpClient.auth().get(environment.userUrl + '/api/persons/');
  }

  checkSupervisorKey(body?: any) {
    return this.httpClient.auth().post(environment.userUrl + '/api/persons/chksupkey', body);
  }

  changePassword(body?: any) {
    return this.httpClient.auth().put(environment.userUrl + '/api/persons/chpw', body);
  }

  resetPassword(body?: any) {
    return this.httpClient.auth().put(environment.userUrl + '/api/persons/rspw', { userId: body });
  }

  checkExpirePassword() {
    return this.httpClient.auth().put(environment.userUrl + '/api/persons/eppw', {});
  }

  
}
