export class FormQuestion {
    id: number
    formName: string;
    formtDetail: string;
    question: Question[];
    suggestion: string;
    email: string;
    isActive: number;
    language: string;
}

export class Question {
    id: number;
    formId: number;
    questionName: string;
    answerRow: any[];
    answerColumn: any[];
    typeQuestionId: number;
}

export class FromAnswer {
    questionid: number;
    formId: string;
    custId: string
    questionName: string;
    answer: any;
    groupAnswer: number;
    typeQuestionId: number;
    check: boolean;
    section: number;
}

export class TypeQuestion {
    id: number;
    typeQuestionName: string;
}

export class MapDataForSend {
    id: number;
    formId: string;
    custId: string;
    customerName: string;
    sendDate: Date;
    everySend: number;
    isChecked: boolean;
    email: string;
    token: string;
    checkToken: string;
}

export class MapLanguage {
    id: number;
    gName: string;
    formId: any[];
}

export class MongoUpdate {
    filters: MongoFilter[];
    datas: MongoFilter[];
}

export class MongoFilter {
    name: string;
    value: any;
    type: string;

    constructor(n?: string, v?: string) {
        this.name = n;
        this.value = v;
    }

    add(){

    }

    update() {

    }

}
