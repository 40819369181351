import { CustomerSurveyService } from 'app/shared/service/customer-survey-service';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { CustomEmail } from 'app/shared/model/custom-email.model';
import { MessageService } from 'primeng/api';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-emailcontent',
  templateUrl: './emailcontent.component.html',
  styleUrls: ['./emailcontent.component.css'],
  providers: [MessageService],
})
export class EmailcontentComponent implements OnInit {

  blocked: boolean = true;
  customEmails: any[] = [];
  dialog:boolean = false;
  labelContent = " *ถ้าต้องการแนบ Link Customer Survey ให้ใช้ https://survey.yamatothai.com/#/showform?token={0}";
  customEmail:CustomEmail = {
    id: 0,
    templateName: '',
    emailSubject: '',
    emailContent: ''
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    toolbarHiddenButtons: [
      ['insertImage',
      'insertVideo'],
      
    ],
    
};

  submitted: boolean;

  constructor(private customerSurveyService:CustomerSurveyService,private messageService :MessageService) { }

  ngOnInit(): void {
    this.closeDialog();
  }

  getCustomEmailList()
  {
    this.customerSurveyService.getCustomEmailList().subscribe((res: any) => {
      this.customEmails = res;
      this.blocked = false;
    });
  }

  EditCustomEmail(customEmailData: any, index: number) {
    this.dialog = true;
    this.customEmail = customEmailData;
  }

  closeDialog()
  {
    this.customEmail.templateName = "";
    this.customEmail.emailSubject = "";
    this.customEmail.emailContent = "";
    this.customEmail.id = 0;
    this.getCustomEmailList();
    this.dialog = false;
  }

  save()
  {
    this.blocked = true;
    const cmd = {
      Id : this.customEmail.id,
      TemplateName : this.customEmail.templateName,
      EmailSubject : this.customEmail.emailSubject,
      EmailContent : this.customEmail.emailContent,
      IsDelete : false
    }

    this.customerSurveyService.saveCustomEmail(cmd).subscribe((res: any) => {
      this.showSuccess();
      this.closeDialog();
    },(error: any) =>{
      this.showWarn();

    });
  }

  DeleteCustomerGroup(cus) {
    swal({
      title: "Are you sure?",
      text: "You will delete this custom email?",
      showCancelButton: true,
      confirmButtonText: "Disable",
      confirmButtonColor: "#ffa51f",
      allowOutsideClick: false
    }).then((result) => {
      //
      if (result.value) {
       
      }
    });
  }

  showSuccess() {
    this.messageService.add({
      severity: "success",
      summary: "Service Message",
      detail: "Save Successful",
    });
  }

  showWarn() {
    this.messageService.add({
      severity: "warn",
      summary: "Service Message",
      detail: "Save failed",
    });
  }
}
