import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenHelperService } from "app/core/authentication/token-helper.service";
import { Form } from "app/shared/model/design-form-question";
import { QualityManagementService } from "app/shared/service/quality-management-service";
import { adsense } from "googleapis/build/src/apis/adsense";
import swal from "sweetalert2";
import { ConfirmationService, MessageService } from "primeng/api";
import { CustomerSurveyService } from "app/shared/service/customer-survey-service";
import { stringify } from "querystring";
import { CommonModule } from "@angular/common";
import { L, X } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { isNull } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-questionnaire",
  templateUrl: "./questionnaire.component.html",
  styleUrls: ["./questionnaire.component.css"],
  providers: [MessageService, ConfirmationService],
})
export class QuestionnaireComponent implements OnInit {
  @Input() formQuestion: Form;
  @Input() preview: string;
  formQuestions: Form;

  token: any;
  formId: any;
  custId: any;
  historys: any;
  selectedValues: any[] = [];
  rows: number = 0;
  chkFormReceive = false;
  chkUnable = false;
  blocked: boolean = true;

  filterargs = true;
  inputText: number[] = [];
  selectedService: any[] = [];
  services = [];
  // services = [
  //   { id: 1, serviceName: "Air Frieight" },
  //   { id: 2, serviceName: "Sea Frieight" },
  //   { id: 3, serviceName: "Cross Border" },
  //   { id: 4, serviceName: "Warehouse" },
  //   { id: 5, serviceName: "Packing" },
  //   { id: 6, serviceName: "Transport" },
  // ];
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private messageService: MessageService,
    private customerSurveyService: CustomerSurveyService
  ) {
    this.route.queryParams.subscribe((data) => {
      if (data.token) {
        this.token = data.token;
        this.chkDevice();
        this.customerSurveyService
          .getHistoryById(data.token)
          .subscribe((res: any) => {
            this.historys = res;
            this.formQuestions = this.historys[0].form;
            this.formQuestion = JSON.parse(JSON.stringify(this.formQuestions));

            this.service().subscribe((x: any) => {
              if (x) {
                console.log(this.services)
                if (this.services.length > 0) {
                  this.formQuestion.topics = [];
                  this.formQuestions.topics.forEach((x) => {
                    if (!x.serviceId) {
                      x.serviceId = 0;
                      this.formQuestion.topics.push(x);
                    }
                  });
                }else{
                  this.formQuestions.topics.forEach((x) => {
                    if (!x.serviceId) {
                      x.serviceId = 0;
                    }
                  });
                }

                if (this.historys[0].isReceive) {
                  this.chkFormReceive = true;
                }
                this.positionNumber();
              }
            });
          });
      }
    });
  
  }

  ngOnInit(): void {}

  ngOnChanges() {
    this.rows = 0;
    if (this.preview == "preview") {
      this.selectedValues = [];
      this.formQuestion.topics.forEach((x, i) => {
        if (x.typeId == 2 || x.typeId == 4) {
          x.questions.forEach((y) => {
            y.rowsNumber = this.rows;
            this.selectedValues.push([]);
            this.rows++;
          });
        }
      });
      this.service();
      this.blocked = false;
    } else {
      this.selectedValues = [];
      this.formQuestion.topics.forEach((x, i) => {
        if (x.typeId == 2 || x.typeId == 4) {
          x.questions.forEach((y) => {
            y.rowsNumber = this.rows;
            this.selectedValues.push([]);
            this.rows++;
          });
        }
      });
      this.service();
      this.blocked = false;
    }
  }

  service() {
    const x = new Observable((obs) => {
      this.customerSurveyService.getServices().subscribe((res: any) => {
        console.log(res);

        this.formQuestions.topics
          .filter((t) => t.isActive == true)
          .forEach((topic) => {
            topic.typeLock = true;
            const sv = res.find((s) => s.id == topic.serviceId);
            if (sv) {
              topic.serviceName = sv.serviceName;
              var serviceDuplicate = this.services.find((s) => s.id == sv.id);

              if (!serviceDuplicate) {
                this.services.push(sv);
              }
            }
            this.services.sort(function (a, b) {
              return a.id - b.id || a.id.localeCompare(b.id);
            });
          });
        obs.next(true);
      });
      console.log(this.services.length);
    });
    return x;
  }

  scroll(str: string) {
    document
      .querySelector(str)
      .scrollIntoView({ behavior: "smooth", block: "center" });
  }
  searchSelection() {
    if (this.selectedService.length > 0) {
      this.formQuestion.topics = this.formQuestions.topics.filter((x) =>
        this.selectedService.some(
          (s) => parseInt(s, 10) === x.serviceId || x.serviceId === 0
        )
      );
    } else {
      this.formQuestion.topics = this.formQuestions.topics.filter(
        (x) => x.serviceId === 0
      );
    }
    this.formQuestion.topics.sort((a, b) => a.serviceId - b.serviceId);
    console.log(this.formQuestions.topics);
  }
  saveData() {
    this.customerSurveyService
      .getHistoryById(this.token)
      .subscribe((res: any) => {
        if (res[0].isReceive) {
          console.log("ไม่ save");
          this.chkUnable = true;
        } else {
          const str = "#Q";
          let isAnswer = true;
          let arrayData = [];

          for (let x = 0; x < this.formQuestion.topics.length; x++) {
            if (this.formQuestion.topics[x].isActive == false) continue;
            if (isAnswer === false) break;
            for (
              let y = 0;
              y < this.formQuestion.topics[x].questions.length;
              y++
            ) {
              if (this.formQuestion.topics[x].questions[y].isActive == false)
                continue;
              isAnswer = this.formQuestion.topics[x].questions[y].answers.some(
                (yy) => {
                  return yy.isAns === true;
                }
              );
              if (this.formQuestion.topics[x].typeId == 5) isAnswer = true;
              if (isAnswer === false) {
                console.log("ffsf");

                arrayData = [];
                this.scroll(str.concat(x.toString(), y.toString()));
                break;
              } else {
                console.log("else");
                for (
                  let z = 0;
                  z < this.formQuestion.topics[x].questions[y].answers.length;
                  z++
                ) {
                  if (
                    this.formQuestion.topics[x].questions[y].answers[z].isAns ==
                      true ||
                    this.formQuestion.topics[x].typeId == 5
                  ) {
                    console.log(
                      this.formQuestion.topics[x].questions[y].answers[z]
                        .textAnswer
                    );

                    arrayData.push({
                      AnswerId:
                        this.formQuestion.topics[x].questions[y].answers[z].id,
                      ShortTextDetail:
                        this.formQuestion.topics[x].questions[y].answers[z]
                          .textAnswer,
                    });
                  }
                }
              }
            }
          }

          if (arrayData.length > 0) {
            const data = {
              HistoryId: this.historys ? this.historys[0].historyId : 0,
              AnswersDetail: arrayData,
            };
            console.log(data);

            this.customerSurveyService
              .saveCustomerAnswer(data)
              .subscribe((res: any) => {
                window.location.reload();
              });
          }
          console.log("เข้า save");
        }
      });
  }

  onSelect(ansId: any, que: any) {
    que.answers.forEach((x) => {
      if (x.id == ansId) {
        x.isAns = true;
      } else {
        x.isAns = false;
      }
    });
  }

  onMutiSelect(que: any) {
    que.answers.forEach((x) => {
      const ids = this.selectedValues[que.rowsNumber].find((y) => x.id == y);
      if (ids) {
        x.isAns = true;
      } else {
        x.isAns = false;
      }
    });
  }

  showWarn(key: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Empty " + key,
      detail: "Empty Data  !!",
    });
  }

  chkDevice() {
    console.log("in");

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      console.log("inmoblile");
      console.log(this.token);
      this.router.navigate(["/showformm"], {
        queryParams: { token: this.token },
      });
    } else {
      console.log("out");
    }
  }

  valueChange(value, i) {
    this.inputText[i] = 0;
    this.inputText[i] = value;
  }

  positionNumber() {
    var topicPosition = 0;
    var questionPosition = 0;
    var answerPosition = 0;

    this.formQuestion.topics.forEach((topic, topicIndex) => {
      if (topic.isActive) {
        topicPosition += 1;
        topic.topicPosition = topicPosition;
      } else {
        topic.topicPosition = null;
      }

      if (topicIndex == this.formQuestion.topics.length - 1) {
        topicPosition = 0;
      }

      topic.questions.forEach((question, questionIndex) => {
        if (question.isActive) {
          questionPosition += 1;
          question.questionPosition = questionPosition;
        } else {
          question.questionPosition = null;
        }

        if (questionIndex == topic.questions.length - 1) {
          questionPosition = 0;
        }

        question.answers.forEach((answer, answerIndex) => {
          if (answer.isActive) {
            answerPosition += 1;
            answer.answerPosition = answerPosition;
          } else {
            answer.answerPosition = null;
          }

          if (answerIndex == question.answers.length - 1) {
            answerPosition = 0;
          }
        });
      });
    });
    this.blocked = false;
  }
}
