import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { DesignFormQmComponent } from "./management/management/design-form-qm/design-form-qm.component";
import { AuthenticationGuard } from "./core/authentication/authentication.guard";
import { LoginComponent } from "./login/login.component";
import { QuestionnaireComponent } from "./management/management/design-form-qm/questionnaire/questionnaire.component";
import { QuestionnaireMComponent } from "./management/management/design-form-qm/questionnaire-m/questionnaire-m.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "formlist",
    pathMatch: "full",
  },
  { path: "login", component: LoginComponent },
  { path: 'showform', component: QuestionnaireComponent },
  { path: 'showform/:token', component: QuestionnaireComponent },
  { path: 'showformm', component: QuestionnaireMComponent },
  { path: 'showformm/:token', component: QuestionnaireMComponent },
  {
    path: "",
    // canActivate: [AuthenticationGuard],
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./layouts/admin-layout/admin-layout.module#AdminLayoutModule",
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
