import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IdentityService } from 'app/core/authentication/identity.service';
import { UserData } from 'app/shared/model/user-data.model';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-input-requestor-info',
  templateUrl: './input-requestor-info.component.html',
  styleUrls: ['./input-requestor-info.component.scss']
})
export class InputRequestorInfoComponent implements OnInit {
  data = {
    userName: '', password: 'Zxcv123!',
  };
  userData: UserData;
  projectId = 'f1354fef-6407-423c-b6e0-58a3f0f08ad0';
  showErrorMessage = false;
  errorMessage = '';
  constructor(
    private identityService: IdentityService,
    public ref: DynamicDialogRef,
  ) { }

  ngOnInit() {
    // this.userData = this.identityService.getUserDataStorage();
  }
  checkData() {
    let result = true;
    for (const key in this.data) {
      if (this.data[key] === '' || this.data[key] === undefined) {
        // this.toastrService.warning('', 'Please fill ' + key + ' field');
        result = false;
      }
    }
    return result;
  }

  onSubmitInfo() {
    this.errorMessage = '';
    if (this.checkData()) {
      this.identityService.ResetAuthorizationData2();
      if (this.userData) {
        this.projectId = this.userData.projectId;
      }
      this.identityService.loginType2(this.data, this.projectId).subscribe((rs: any) => {
        this.identityService.SetAuthorizationData2(rs);
      }, (err: HttpErrorResponse) => {
        this.showErrorMessage = true;
        if (err.status === 400) {
          this.errorMessage = 'Employee ID or password is incorrect';
        }
      }, () => {
        this.showErrorMessage = false;
        this.onClose();
      });
    }
  }

  onClose() {
    
    this.ref.close();
  }

}
