import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Logger } from '../logger.service';
import { IdentityService } from '../authentication/identity.service';
import Swal from 'sweetalert2';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputRequestorInfoComponent } from 'app/management/input-requestor-info/input-requestor-info.component';
import { Route, Router } from '@angular/router';
import { LoginComponent } from 'app/login/login.component';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private identityService: any;
  

  constructor(private inject: Injector,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public router:Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.identityService = this.inject.get(IdentityService);
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (response instanceof HttpErrorResponse) {
      // 
      switch (response.status) {
        case 401:
          this.HandleError(response.status);
          break
        case 403:
          this.identityService.HandleError(response.status);
          break;
        case 500:
          // 
          // #JobComment, Error ยาวไป
          // if (response.error.ResponseException) {
          //   str += '<p><b>Error Details</b>: ' + response.error.ResponseException.Details + '<p>';
          //   str += '<p><b>Exception Message</b>: ' + response.error.ResponseException.ExceptionMessage + '<p>';
          //
          const errorMessage = response.error.ResponseException
            ? response.error.ResponseException.ExceptionMessage
            : response.error.Message;
          Swal({
            title: 'Error!',
            text: errorMessage,
            type: 'error',
            confirmButtonText: 'Close'
          });

          break;
        default:
          break;
      }
    }
    throw response;
  }

  private HandleError(error: any) {
    
    if (error === 403) {
      
    } else if (error === 401) {

      // 
      // 
      // this.dialogService.open(LoginComponent, {
      //   header: 'Login Customer Survey ',
      //   width: '100%'
      // });

      

      

      // this.dialogService.open(LoginComponent, {

      //   header: 'Login Customer Survey ',

      //   width: '100%'

      // });
      // 
      this.router.navigate(['/login']);
    }
  }
}
