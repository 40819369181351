import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DialogService } from 'primeng/dynamicdialog';
import { InputRequestorInfoComponent } from './input-requestor-info.component';

@NgModule({
  declarations: [InputRequestorInfoComponent],
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  entryComponents: [InputRequestorInfoComponent],
  providers: [DialogService]
})
export class InputRequestorInfoModule { }
