import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import * as _ from "lodash";
import { IdentityService } from "app/core/authentication/identity.service";
import swal from "sweetalert2";
import { MessageService } from "primeng/api";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [MessageService],
})
export class LoginComponent implements OnInit {
  data = {
    userName: "",
    password: "",
  };
  projectId = "f1354fef-6407-423c-b6e0-58a3f0f08ad0";
  login: boolean = false;
  urlSafe: SafeResourceUrl;
  blocked:boolean = false;
  passInvalid:boolean = false;

  constructor(
    private identityService: IdentityService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private messageService: MessageService
  ) { }

  ngOnInit(): void { }

  onLogin() {
    this.blocked = true;
      this.identityService.loginType2(this.data, this.projectId).subscribe(
        async (rs: any) => {
          if (rs) {
            this.messageService.add({
              severity: "success",
              summary: "Service Message",
              detail: "Login Successful",
            });
          }
          await this.identityService.SetAuthorizationData2(rs);
          this.login = true;
          this.passInvalid = false;
        },
        (err) => {
          this.blocked = false;
          this.passInvalid = true;
          this.data.password = "";
          this.messageService.add({
            severity: "warn",
            summary: "Service Message",
            detail: "Login invalid",
          });
        },
        () => {

        }
      );
  }
  checkData() {
    let result = true;
    for (const key in this.data) {
      if (this.data[key] === "" || this.data[key] === undefined) {
        // this.toastrService.warning('', 'Please fill ' + key + ' field');
        result = false;
      }
    }
    return result;
  }

  transform(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  yutlocalAccount(){
    swal({
      title: 'YUT-Local Account',
      showConfirmButton: true,
      html: 'WMS<br>Back Office<br>Management<br>Air Express<br>Customer Survey<br>Transport',
      allowOutsideClick: true
    })
  }
}
