import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Form } from 'app/shared/model/design-form-question';
import { CustomerSurveyService } from 'app/shared/service/customer-survey-service';
import { QualityManagementService } from 'app/shared/service/quality-management-service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { switchMap } from 'rxjs/operators';
import swal from 'sweetalert2';

@Component({
  selector: 'app-formlist',
  templateUrl: './formlist.component.html',
  styleUrls: ['./formlist.component.css'],
  providers: [MessageService, ConfirmationService, DatePipe],
})
export class FormlistComponent implements OnInit {
  allForm = [];
  allFormSwich = [];
  setSendEmails: any;
  selectedValuesFm: string[] = ['true', 'false'];
  message:number = 0;
  blocked:boolean = true;
  form: Form;

  constructor(
    private customerSurveyService: CustomerSurveyService,
    public messageService: MessageService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
  ) {
    this.getListForm();
    this.getSetSendEmailList();
    this.route.params.subscribe(params => {
      if(params['id'] > 0){
        
        this.message = params['id'];
      }
    });
  }

  ngOnInit(): void {
    
    if(this.message > 0){
      
      this.saveMessage();
    }

  }
  saveMessage(){
    this.messageService.add({
      severity: "success",
      summary: "Service Message",
      detail: "Save Successful",
    })
  }

  getListForm() {
    this.customerSurveyService.getFormListForPage().subscribe((res: any) => {
      
      res.forEach(r => {
        this.allFormSwich.push(r);
      });
      this.allForm = res;
    });
  }

  getSetSendEmailList() {
    this.customerSurveyService.getSetSendEmailList().subscribe((res: any) => {
      //
      this.setSendEmails = [];
      res.forEach((x) => {
        this.setSendEmails.push({
          SetSendEmailId: x.SetSendEmailId,
          CustomerGroupName: x.CustomerGroupName,
          GroupCustomerId: x.GroupCustomerId,
          FormId: x.FormId,
          FormName: x.FormName,
          CustomEmailId: x.CustomEmailId,
          TemplateName: x.TemplateName,
          SendDate: x.SendDate,
          SendCount: x.SendCount,
          ReSendDay: x.ReSendDay,
          IsActive: x.IsActive,
          IsInvoice30day: x.IsInvoice30day
        });
      });
      this.blocked = false;
    });
  }

  deleteForm(form) {
    swal({
      title: "Are you sure?",
      text: "You will delete this form?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#ffa51f",
      allowOutsideClick: false
    }).then((result) => {
      
      if (result.value) {
        this.customerSurveyService.getForm(form.id).subscribe((res: any) => {
          
          var topics = [];
          var questions = [];
          var answers = [];

          res.topics.forEach(topic => {
            topics.push(topic.id);
            topic.questions.forEach(question => {
              questions.push(question.id);
              question.answers.forEach(answer => {
                answers.push(answer.id);
              });
            });
          });

          var delForm = {
            id: res.id,
            topic: topics,
            question: questions,
            answer: answers
          }
          this.customerSurveyService.deleteForm(delForm).subscribe((res: any) => {
            this.getListForm();
            this.messageService.add({
              severity: "error",
              summary: "Service Message",
              detail: "Delete" + "Form: " + form.formName,
            });
          });

        });
        

      }
    });
  }

  handleChangeForm(event, form, i) {
    

    var setSendEmailDisable = [];
    if (event.checked == false) {
      this.setSendEmails.forEach((setData) => {
        //
        if (setData.FormId == form.id && setData.IsActive == true) {
          setData.Id = setData.SetSendEmailId;
          setSendEmailDisable.push(setData);
        }
      });

      if (setSendEmailDisable.length > 0) {
        swal({
          title: "Are you sure?",
          text: "If you disable this form,set send email that are active in this form will also be disable",
          showCancelButton: true,
          confirmButtonText: "Disable",
          confirmButtonColor: "#ffa51f",
          allowOutsideClick: false
        }).then((result) => {
          // 
          if (result.value) {
            setSendEmailDisable.forEach(setDisableData => {
              setDisableData.IsActive = false;
            });
            this.customerSurveyService
              .saveSetSendEmailsMultiple(setSendEmailDisable)
              .subscribe((r: any) => {
                this.getSetSendEmailList();
                this.customerSurveyService.setIsActiveForm(form.id, event.checked).subscribe(r => {

                  this.messageService.add({
                    severity: "warn",
                    summary: "Service Message",
                    detail: "Disable " + "Form: " + form.formName,
                  });
                  this.sortData();
                },
                  (err: any) => {
                    this.showWarn();
                  });

              });
          } else {
            //
            this.allForm[i].isActive = true;
            this.sortData();
          }
        });
      } else {
        this.customerSurveyService.setIsActiveForm(form.id, event.checked).subscribe(r => {

          this.messageService.add({
            severity: "warn",
            summary: "Service Message",
            detail: "Disable " + "Form: " + form.formName,
          });

          this.sortData();
        },
          (err: any) => {
            this.showWarn();
          });
      }
    } else {
      this.customerSurveyService.setIsActiveForm(form.id, event.checked).subscribe(r => {

        this.messageService.add({
          severity: "success",
          summary: "Service Message",
          detail: "Enable " + "Form: " + form.formName,
        });

        this.sortData();
      },
        (err: any) => {
          this.showWarn();
        });
    }
  }

  showWarn() {
    this.messageService.add({
      severity: "error",
      summary: "Service Message",
      detail: "Save failed",
    });
  }

  sortData() {
    this.allForm.sort((a, b) => {
      return b.isActive - a.isActive;
    });
  }

  searchSelectionForm() {
    
    
    this.allForm = this.allFormSwich.filter((x) =>
      this.selectedValuesFm.some((s) => this.getBoolean(s) === x.isActive)
    );
  }

  getBoolean(value) {
    switch (value) {
      case true:
      case "true":
        return true;
      default:
        return false;
    }
  }

  duplicateForm(form)
  {
    swal({
      title: "Are you sure?",
      text: "You will Duplicate Form",
      showCancelButton: true,
      confirmButtonText: "Save",
      confirmButtonColor: "#ffa51f",
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.customerSurveyService.getForm(form.id).subscribe((res: any) => {
          this.form = res;
          this.form.id = 0;
          this.form.topics.forEach(topic => {
            topic.id = 0;
            topic.questions.forEach(question => {
              question.id = 0;
              question.answers.forEach(answer => {
                answer.id = 0;
              });
            });
          });
          console.log(this.form);
          //service
          this.customerSurveyService
              .saveForm(this.form)
              .subscribe((res: any) => {
                this.getListForm();
                this.messageService.add({
                  severity: "success",
                  summary: "Service Message",
                  detail: "Duplicate success",
                });


              }, (err: any) => {
                this.messageService.add({
                  severity: "warn",
                  summary: "Service Message",
                  detail: "Duplicate failed",
                });
              });
        });
      }
    });
  }

}


