import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapLanguage } from 'app/shared/model/form-question';
import { QualityManagementService } from 'app/shared/service/quality-management-service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-config-language',
  templateUrl: './config-language.component.html',
  styleUrls: ['./config-language.component.css']
})

export class ConfigLanguageComponent implements OnInit {


  languageObject: any[];
  selectLang: any;

  listLangs: any[] = [{ label: 'EN', val: 'en-US' },
  { label: 'TH', val: 'th-TH' },
  { label: 'JP', val: 'jp-JP' }];
  constructor(
    public router: Router,
    private qualityManagementService: QualityManagementService
  ) {
    this.selectLang = 'en-US';
  }

  ngOnInit(): void {
    this.getConfigLang();
  }

  getConfigLang() {
    this.qualityManagementService.getLang(this.selectLang).subscribe((res: any) => {
      if (res) {
        const keys = Object.keys(res);
        this.languageObject = keys.map(m => Object.assign({ key: m, value: res[m] }));
      }
    });
  }


  saveLangauage() {
    const datas = this.languageObject.map((m) => {
      let o = {};
      o[m.key] = m.value;
      return o;
    });
    
    const obj = {
      TableName: this.selectLang,
      Langs: datas
    }
    this.qualityManagementService.saveData(obj).subscribe((res: any) => {
      if (res) {
        
      }
    });
  }


}
