import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapLanguage } from 'app/shared/model/form-question';
import { QualityManagementService } from 'app/shared/service/quality-management-service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-map-language',
  templateUrl: './map-language.component.html',
  styleUrls: ['./map-language.component.css']
})

export class MapLanguageComponent implements OnInit {
  listForm: any[] = [];
  selectForm: any[] = [];
  groupFormName: string;
  MapLanguage: MapLanguage;
  MapLanguagelist: MapLanguage[]
  selectMapLang: any;
  mapId: number;
  module: string;

  languageObject: any[];
  constructor(
    public router: Router,
    private qualityManagementService: QualityManagementService
  ) {
  }

  ngOnInit(): void {
    this.qualityManagementService.getLang('en-US').subscribe((res: any) => {
      if (res) {
        const keys = Object.keys(res);
        this.languageObject = keys.map(m => Object.assign({ key: m,value: res[m] }));
      }
    });

    this.qualityManagementService.getFormList('QualityForm').subscribe((res: any) => {
      this.listForm = [];
      res.forEach(x => {
        this.listForm.push({ id: x.id, name: x.formName, language: x.language });
      });
      this.MapLanguage = new MapLanguage();
    });

    this.qualityManagementService.getFormList('MapFormLanguage').subscribe((res1: any) => {
      this.MapLanguagelist = res1;
      this.mapId = this.getMaxId(this.MapLanguagelist);
    });
    this.module = 'show'
  }

  getMaxId(data) {
    return Math.max.apply(Math, data.map(function (o) { return o.id + 1; }))
  }

  selectMap(a: any) {
    this.module = 'input'
    this.selectMapLang = this.MapLanguagelist.filter(x => x.id === a);
    if (this.selectMapLang) {
      this.groupFormName = this.selectMapLang[0].gName;
      this.selectForm = this.selectMapLang[0].formId;
      this.mapId = this.selectMapLang[0].id;
    }
    
    
  }

  NewMapData() {
    this.module = 'input'
    this.groupFormName = '';
    this.selectForm = [];
    this.mapId = this.getMaxId(this.MapLanguagelist);
  }

  saveMapData() {
    swal({
      title: 'Are you sure?',
      text: 'You will Save Form!',
      showCancelButton: true,
      confirmButtonText: 'Save',
    }).then((result: any) => {
      if (result.value) {
        this.MapLanguage.formId = this.selectForm;
        this.MapLanguage.gName = this.groupFormName;
        this.MapLanguage.id = this.mapId;
        const saveData = {
          tableName: 'MapFormLanguage',
          Datas: [this.MapLanguage],
          Keywords: ['id']
        }
        
        this.qualityManagementService.listsaveClearOld(saveData).subscribe((res: any) => {
          alert('Save complete!!');
          this.router.navigate(['/formlist']);
        });
      }
      // } else {
      //   // alert('Can\'t Save!!');
      // }
    });
  }
}
