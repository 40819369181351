import { Injectable } from '@angular/core';
import { isNil } from 'lodash';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class HttpHelper {
    setURLParams(options: object) {
        const params = new URLSearchParams();
        // tslint:disable-next-line:forin
        for (const key in options) {
            params.set(key, options[key]);
        }
        return params;
    }

    downloadFile(data: any, fileName: string = 'File') {
        let name = data.headers.get('content-disposition');
        name = name.substring(name.indexOf('=') + 1).replace(/[:*?"<>|/\\]/g, '');
        name = name.substring(0, name.indexOf(';'));
        const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = name;
        anchor.href = url;
        anchor.click();
        anchor.remove();
        URL.revokeObjectURL(url);
    }

    downloadFileCSV(data: any, fileName: string = 'File', charset: string = '') {
        const blob = new Blob([data.body], { type: 'text/csv;' + charset });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = fileName;
            anchor.href = url;
            anchor.click();
            anchor.remove();
            URL.revokeObjectURL(url);
        }

    }

    exportToPDFPopup(data: any): void {
        const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
        const url = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
    }
}
