import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CustomerSurveyService {
  constructor(private http: HttpClient) {}

  saveForm(body: any) {
    return this.http.post(environment.cs + '/CustomerSurvey/saveForm', body, {
      responseType: "text",
    });
  }
  getFormList() {
    return this.http.get(environment.cs + '/CustomerSurvey/getFormList/');
  }
  getFormListForPage(){
    //console.log(environment.cs);
    
    return this.http.get(environment.cs + '/CustomerSurvey/getFormListForPage/');
  }
  getForm(id: any) {
    return this.http.get(environment.cs + '/CustomerSurvey/getForm?id='+ id);
  }
  getHistoryById(id:any){
    
    return this.http.get(environment.cs + '/CustomerSurvey/getHistoryById?id='+ id);
  }
  getType() {
    return this.http.get(environment.cs + '/CustomerSurvey/getTypeQuestion/');
  }
  getCustomerGroupList(){
    return this.http.get(environment.cs + '/CustomerSurvey/getCustomerGroupList/');
  }
  saveCustomerGroup(body:any){
    
    
    // const last = str.charAt(str.length - 1);
    return this.http.post(environment.cs + '/CustomerSurvey/saveCustomerGroup', body);
  }
  saveSetSendEmail(body:any){
    return this.http.post(environment.cs + '/CustomerSurvey/saveSetSendEmails', body);
  }
  getMoreEmailList() {
    return this.http.get(environment.cs + '/CustomerSurvey/getMoreEmailList/');
  }
  
  saveCustomerAnswer(body:any){
    return this.http.post(environment.cs + '/CustomerSurvey/saveCustomerAnswer',body , {
      responseType: "text",
    });
  }
  getSetSendEmailList(){
    return this.http.get(environment.cs + '/CustomerSurvey/getSetSendEmailsList/');
  }
  getCustomerList(){
    return this.http.get(environment.cs + '/CustomerSurvey/getCustomerList/');
  }

  getPersonalList(){
    return this.http.get(environment.cs + '/CustomerSurvey/getPersonalList/');
  }
  
  saveSetSendEmailsMultiple(body:any){
    return this.http.post(environment.cs + '/CustomerSurvey/saveSetSendEmailsMultiple', body);
  }

  deleteCustomerGroupById(id:number){
    return this.http.delete(environment.cs + '/CustomerSurvey/deleteCustomerGroupById?id=' + id, {
      responseType: "text", 
    });
  }

  deleteSetSendEmailsById(id:number){
    return this.http.delete(environment.cs + '/CustomerSurvey/deleteSetSendEmailsById?id='+ id);
  }

  setIsActiveForm(id:number,isActive:boolean){
    return this.http.post(environment.cs + '/CustomerSurvey/setIsActiveForm',{
      id: id,
      isActive: isActive,
    },);
  }

  deleteForm(body:any){
    return this.http.post(environment.cs + '/CustomerSurvey/deleteForm', body, {
      responseType: "text",
    });
  }

  getChkFormSend(id:number){
    return this.http.get(environment.cs + '/CustomerSurvey/getChkFormSend?id='+ id);
  }

  getServices() {
    return this.http.get(environment.cs + '/CustomerSurvey/getServices/');
  }

  getCustomEmail(id: any) {
    return this.http.get(environment.cs + '/CustomerSurvey/getCustomEmail?id='+ id);
  }

  getCustomEmailList() {
    return this.http.get(environment.cs + '/CustomerSurvey/getCustomEmailList/');
  }

  saveCustomEmail(body: any) {
    return this.http.post(environment.cs + '/CustomerSurvey/saveCustomEmail', body, {
      responseType: "text",
    });
  }

  getExportHistory(id: any): void {
    this.http.get(environment.cs + '/CustomerSurvey/getExcelHistoryFileResult?setId=' + id, { responseType: 'blob' })
    .subscribe((excelData: Blob) => {
      // Create a blob object and create a URL for it
      const url = window.URL.createObjectURL(excelData);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;

      // Set the filename for the downloaded file
      link.setAttribute('download', `ReceiveHistory_${id}.xlsx`);

      // Append the link to the document body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, error => {
      // Handle error
      console.error('Error downloading Excel file:', error);
    });
  }

  getExportCusAnswer(id: any): void {
    this.http.get(environment.cs + '/CustomerSurvey/getExcelCusAnswerFileResult?setId=' + id, { responseType: 'blob' })
    .subscribe((excelData: Blob) => {
      // Create a blob object and create a URL for it
      const url = window.URL.createObjectURL(excelData);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;

      // Set the filename for the downloaded file
      link.setAttribute('download', `CustomerAnswer_${id}.xlsx`);

      // Append the link to the document body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, error => {
      // Handle error
      console.error('Error downloading Excel file:', error);
    });
  }
}
