import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { UserProfileComponent } from '../../user-profile/user-profile.component';
import { TableListComponent } from '../../table-list/table-list.component';
import { TypographyComponent } from '../../typography/typography.component';
import { IconsComponent } from '../../icons/icons.component';
import { MapsComponent } from '../../maps/maps.component';
import { NotificationsComponent } from '../../notifications/notifications.component';
import { UpgradeComponent } from '../../upgrade/upgrade.component';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import { DesignFormQmComponent } from 'app/management/management/design-form-qm/design-form-qm.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'app/shared/shared.module';
import {MatChipsModule} from '@angular/material/chips'
import { QuestionnaireComponent } from 'app/management/management/design-form-qm/questionnaire/questionnaire.component';
import { QuestionnaireMComponent } from 'app/management/management/design-form-qm/questionnaire-m/questionnaire-m.component';
import { FormlistComponent } from 'app/management/management/design-form-qm/formlist/formlist.component';
import { MergeformComponent } from 'app/management/management/design-form-qm/mergeform/mergeform.component';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MapLanguageComponent } from 'app/management/management/design-form-qm/map-language/map-language.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigLanguageComponent } from 'app/management/config-language/config-language.component';
import { FormDesignComponent } from 'app/management/management/design-form-qm/form-design/form-design.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { myFilterPipe } from 'app/shared/myFilterPipe';
import { BlockUIComponent } from 'app/management/management/design-form-qm/block-ui/block-ui.component';
import { EmailcontentComponent } from 'app/management/management/design-form-qm/emailcontent/emailcontent.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    SharedModule,
    MatChipsModule,
    MatListModule,
    MatCheckboxModule,
    TranslateModule,
    MatDatepickerModule, 
    MatNativeDateModule,
    AngularEditorModule
  ],
  declarations: [
    DashboardComponent,
    UserProfileComponent,
    TableListComponent,
    TypographyComponent,
    IconsComponent,
    MapsComponent,
    NotificationsComponent,
    UpgradeComponent,
    DesignFormQmComponent,
    QuestionnaireComponent,
    QuestionnaireMComponent,
    FormlistComponent,
    MergeformComponent,
    MapLanguageComponent,
    ConfigLanguageComponent,
    FormDesignComponent,
    myFilterPipe,
    BlockUIComponent,
    EmailcontentComponent,
    // LoginComponent
  ]
})

export class AdminLayoutModule {}
