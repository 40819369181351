import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrimengModule } from './primeng-module';
@NgModule({
  imports: [CommonModule, PrimengModule],
  declarations: [],
  exports: [PrimengModule]
})
export class SharedModule {}
