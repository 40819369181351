import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import {
  Answer,
  Form,
  Question,
  Topic,
} from "app/shared/model/design-form-question";
import { QualityManagementService } from "app/shared/service/quality-management-service";
import { speedDialFabAnimations } from "../animations";
import swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { ThrowStmt } from "@angular/compiler";
import { CustomerSurveyService } from "app/shared/service/customer-survey-service";
import { Observable } from "rxjs";
import { M } from "@angular/cdk/keycodes";
import { runInThisContext } from "vm";
import { find } from "rxjs-compat/operator/find";

@Component({
  selector: "app-form-design",
  templateUrl: "./form-design.component.html",
  styleUrls: ["./form-design.component.css"],
  providers: [MessageService, ConfirmationService],
  animations: speedDialFabAnimations,
})
export class FormDesignComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    $event.returnValue = true;
  }
  //button//
  fabButtons = [
    {
      icon: "dock",
      value: "previewM",
    },
    {
      icon: "airplay",
      value: "preview",
    },
    {
      icon: "save",
      value: "save",
    },
    {
      icon: "add",
      value: "new",
    },
  ];
  preview: string;
  buttons = [];
  activeIndex: number = 0;
  fabTogglerState = "inactive";
  showPreview: boolean;
  showPreviewM: boolean;
  blocked: boolean = true;
  //button//
  // ----------------------------------
  // subscribe Data and Get Class //
  form: Form;
  topic: Topic;
  question: Question;
  answer: Answer;
  date: any;
  answerList: Answer[] = [];
  // subscribe Data //
  // ----------------------------------
  types: any[] = [];
  isSend: boolean = false;
  topicsId = [];
  questionsId = [];
  answersId = [];

  itemsService: any[] = [];
  serviceGroup: any[] = [];
  oldServiceGroup: any[] = [];
  topicService: any[] = [];

  serviceToggle:boolean = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private customerSurveyService: CustomerSurveyService
  ) {
    this.route.params.subscribe((data) => {
      if (data.id) {
        this.getChkFormSend(data.id).subscribe((r) => {
          this.getService();
          this.customerSurveyService.getForm(data.id).subscribe((res: any) => {
            this.form = res;
            this.positionNumber();
            console.log(this.form);

            //service
            if (this.form.serviceIds) {
              var serviceIds = this.form.serviceIds.split(",");

              this.itemsService.forEach(service => {
                console.log(serviceIds, service.id);

                if (serviceIds.find(s => s == service.id)) {
                  this.serviceGroup.push(service);
                  this.serviceToggle = true;
                }
              });
              this.oldServiceGroup = this.serviceGroup;

            }

            this.checkTopicService();
          });


        });
      } else {
        this.getService();
        this.form = new Form();
        this.form.topics = [];
        this.newTopic();
        this.blocked = false;
      }
    });
  }

  ngOnInit(): void {
    this.customerSurveyService.getType().subscribe((res: any) => {
      this.types = res;
    });
    console.log(this.isSend);
  }

  getService() {
    this.customerSurveyService.getServices().subscribe((res: any) => {
      this.itemsService = res;
    });
    console.log(this.serviceGroup.length);
  }

  disableService()
  {
    if(this.serviceToggle === false)
    {
      console.log('in');
      this.form.serviceIds = "";
      this.serviceGroup = [];
    }
    
  }

  checkTopicService() {
    this.topicService = [];
    this.form.topics.forEach(topic => {
      // topic.typeLock = true;
      var service = this.itemsService.find(s => s.id == topic.serviceId);

      if (service) {
        topic.serviceName = service.serviceName;
        var serviceDuplicate = this.topicService.find((s) => s.id == service.id);
        console.log(topic);

        if (!serviceDuplicate) {
          this.topicService.push(service);
        }
      }
    });
  }

  getChkFormSend(id) {
    const x = new Observable((obs) => {
      this.customerSurveyService
        .getChkFormSend(id)
        .subscribe((res: boolean) => {
          this.isSend = res;
          console.log(this.isSend);
          
          obs.next(true);
        });
    });
    return x;
  }
  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  hideItems() {
    this.fabTogglerState = "inactive";
    this.buttons = [];
  }

  showItems() {
    this.fabTogglerState = "active";
    this.buttons = this.fabButtons;
  }

  selectFabBtn(val: any) {
    if (val === "new") {
      this.newTopic();
    } else if (val === "save") {
      this.onSave();
    } else if (val === "preview") {
      this.preview = "preview";
      this.beforeSave().subscribe((beforeSave: any) => {
        this.showPreview = true;
      });
    } else if (val === "previewM") {
      this.preview = "previewM";
      this.showPreviewM = true;
    }
  }

  checkEdit(ref: any) {
    if (ref.id > 0) {
      ref.isEdit = true;
    } else {
      ref.isEdit = false;
    }
    this.positionNumber();
  }

  newTopic() {
    this.topic = new Topic();
    if (this.form.id > 0) {
      this.topic.formId = this.form.id;
    }
    this.form.topics.push(this.topic);
    this.topic.topicNumber =
      Math.max(...this.form.topics.map((o) => o.topicNumber)) + 1;

    this.topic.questions = [];

    this.question = new Question();
    this.answer = new Answer();
    this.topic.questions.push(this.question);
    this.question.questionNumber =
      Math.max(...this.topic.questions.map((o) => o.questionNumber)) + 1;
    this.question.answers = [];
    this.question.answers.push(this.answer);
    this.answer.answerNumber =
      Math.max(...this.question.answers.map((o) => o.answerNumber)) + 1;
  }

  // ตัวเลือกแบบปกติ //
  addQuestion(topic: any) {
    this.question = new Question();

    this.answer = new Answer();
    if (topic.id > 0) {
      this.question.topicId = topic.id;
    }
    topic.questions.push(this.question);

    this.question.questionNumber =
      Math.max(...topic.questions.map((o) => o.questionNumber)) + 1;
    this.question.answers = [];
    this.question.answers.push(this.answer);
    this.answer.answerNumber =
      Math.max(...this.question.answers.map((y) => y.answerNumber)) + 1;
    if (topic.typeId == 5) {
      this.answer.answerName = 'Short Text Detail';
    }
    this.positionNumber();
  }

  addAnswerQuestion(questions: any) {
    const lastElement = questions.answers[questions.answers.length - 1];
    if (lastElement) {
      if (lastElement.answerName) {
        const ans = new Answer();
        if (questions.id > 0) {
          ans.questionId = questions.id;
        }

        ans.answerNumber =
          Math.max(...questions.answers.map((y) => y.answerNumber)) + 1;
        questions.answers.push(ans);
      }
    }
    else {
      const ans = new Answer();
      if (questions.id > 0) {
        ans.questionId = questions.id;
      }

      ans.answerNumber = 1;
      questions.answers.push(ans);
    }
    this.positionNumber();
  }

  delVal(list: any, delVal: any, type: string) {

    if (type == "topic") {
      this.topicsId.push(delVal.id);
    } else if (type == "question") {
      this.questionsId.push(delVal.id);
    } else if (type == "answer") {
      this.answersId.push(delVal.id);
    }

    const ind = list.findIndex((x) => x == delVal);

    if (ind !== -1) {
      list.splice(ind, 1);
    }
    this.positionNumber();
  }

  delValAns(listQuestion: any, delVal: any) {

    listQuestion.forEach(question => {
      question.answers.forEach(answer => {
        if (answer.answerName == delVal.answerName) {
          this.delVal(question.answers, answer, "answer");
        }
      });
    });
    this.positionNumber();
  }

  checkDup(check, reflist, index, type) {
    const dup = reflist
      .map((x) => x.answerName === check)
      .filter((v) => v).length;
    if (dup > 1) {
      if (type == "Answer") {
        this.showWarn(type);
        reflist[index].answerName = "";
      }
      reflist.pop();
    }
  }

  changeType(topic) {
    if(topic.id){
      topic.questions.forEach(question => {
        this.questionsId.push(question.id);
        question.answers.forEach(answer => {
          this.answersId.push(answer.id);
        });
      });
    }
    topic.questions = [];
    this.addQuestion(topic);
    topic.typeLock = true;
    topic.isEdit = true;
    this.positionNumber();
  }

  changeService(topic) {
    if (topic.id) {
      topic.isEdit = true;
    }
    this.checkTopicService();
  }

  beforeSave() {
    const x = new Observable((obs) => {
      this.form.topics
        .filter((x) => x.typeId == 3 || x.typeId == 4)
        .forEach((y) => {
          const mainAns = y.questions[0].answers;

          y.questions.forEach((que) => {
            console.log(que.answers.length, mainAns.length);
            if (que.id == 0) {
              que.answers = JSON.parse(JSON.stringify(mainAns))
              que.answers.forEach(x => {
                console.log("in");

                x.id = 0;
                x.questionId = 0;
              });
            } else if (que.answers.length < mainAns.length) {
              console.log(que);

              mainAns.forEach((main) => {
                if (main.id == 0) {
                  que.answers.push(main);
                }
              });
            }
            que.answers.forEach((x, i) => {
              if (mainAns[i].id != 0) {
                x.questionId = que.id;
                x.answerName = mainAns[i].answerName;
                x.isEdit = true;
              } else {
                x.questionId = que.id;
                x.isEdit = false;
              }
            });
          });

        });
      obs.next(true);
    });
    return x;
  }

  positionNumber() {

    var topicPosition = 0;
    var questionPosition = 0;
    var answerPosition = 0;

    this.form.topics.forEach((topic, topicIndex) => {
      if (topic.isActive) {
        topicPosition += 1;
        topic.topicPosition = topicPosition;
      }
      else {
        topic.topicPosition = null;
      }

      if (topicIndex == this.form.topics.length - 1) {
        topicPosition = 0;
      }

      topic.questions.forEach((question, questionIndex) => {
        if (question.isActive) {
          questionPosition += 1;
          question.questionPosition = questionPosition;
        }
        else {
          question.questionPosition = null;
        }

        if (questionIndex == topic.questions.length - 1) {
          questionPosition = 0;
        }

        question.answers.forEach((answer, answerIndex) => {
          if (answer.isActive) {
            answerPosition += 1;
            answer.answerPosition = answerPosition;
          }
          else {
            answer.answerPosition = null;
          }

          if (answerIndex == question.answers.length - 1) {
            answerPosition = 0;
          }

        });
      });

    });
    this.blocked = false;
  }

  switchQuestion(question) {
    question.isEdit = true
    this.positionNumber();
  }

  switchAnswer(ans) {
    ans.isEdit = true
    this.positionNumber();
  }

  onSave() {
    this.confirmationService.confirm({
      message: "Are you sure that you want to proceed?",
      header: "Save Format Question",
      icon: "pi pi-exclamation-triangle",
      key: "save",
      accept: () => {
        this.beforeSave().subscribe((beforeSave: any) => {
          if (beforeSave == true) {
console.log(this.form);

            //service
            this.form.serviceIds = "";
            this.serviceGroup.forEach((s, index) => {
              if (index === this.serviceGroup.length - 1) {
                this.form.serviceIds += s.id;
              } else {
                this.form.serviceIds += s.id + ",";
              }
            });

            this.customerSurveyService
              .saveForm(this.form)
              .subscribe((res: any) => {
                const deleteValue = {
                  id: 0,
                  topic: this.topicsId,
                  question: this.questionsId,
                  answer: this.answersId
                }
                console.log(deleteValue.id, this.topicsId.length, this.questionsId.length, this.answersId.length);

                if (deleteValue.id != 0 || this.topicsId.length != 0 || this.questionsId.length != 0 || this.answersId.length != 0) {
                  this.customerSurveyService.deleteForm(deleteValue).subscribe((res: any) => {
                    this.topicsId = [];
                    this.questionsId = [];
                    this.answersId = [];

                    this.customerSurveyService.getForm(this.form.id).subscribe((res: any) => {

                      this.form = res;
                      // this.router.navigate(['/formlist']);
                    });
                  });
                }
                else {
                  this.customerSurveyService.getForm(this.form.id).subscribe((res: any) => {

                    this.form = res;
                    // this.router.navigate(['/formlist']);
                  });
                  // this.router.navigate(['/formlist']);
                }


                this.messageService.add({
                  severity: "success",
                  summary: "Service Message",
                  detail: "Save success",
                });
              }, (err: any) => {
                this.messageService.add({
                  severity: "warn",
                  summary: "Service Message",
                  detail: "Save failed",
                });
              });
          }
        });
      },
      reject: () => { },
    });
  }

  showWarn(key: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Dupicate " + key,
      detail: "Dupicate data please input again !!",
    });
  }

  confirmChange(topic: any) {
    if (topic.typeId) {
      this.confirmationService.confirm({
        message: "Are you sure that you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        key: "edit",
        accept: () => {
          topic.typeLock = false;
        },
        reject: () => {
          topic.typeLock = true;
        },
      });
    } else {
      this.changeType(topic);
    }
  }

  scroll() {
    document
      .querySelector("#data")
      .scrollIntoView({ behavior: "smooth", block: "center" });
  }

  changeActiveTopic(event, topic) {
    console.log('เข้า1', event);

    topic.isActive = event.checked;
    topic.isEdit = true;

    topic.questions.forEach((question) => {
      question.isActive = event.checked;
      question.isEdit = true;

      question.answers.forEach((answer) => {
        answer.isActive = event.checked;
        answer.isEdit = true;
      });
    });
    this.positionNumber();
  }

  handleChangeTopic(event, topic) {
    console.log(event, this.serviceGroup.find(sg => sg.id == topic.serviceId));

    if (!topic.serviceId || (topic.serviceId && this.serviceGroup.find(sg => sg.id == topic.serviceId))) {
      this.changeActiveTopic(event, topic);
    }
    else {
      console.log('เข้า2');
      swal({
        title: "Unable to enable",
        text: topic.serviceName + " service must be enabled before this topic can be enabled.",
        confirmButtonText: "OK",
        confirmButtonColor: "#ffa51f",
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          topic.isActive = false;
        }
      });
    }




  }
  handleChangeQuestion(event, question) {
    question.isActive = event.checked;
    question.isEdit = true;

    question.answers.forEach((answer) => {
      answer.isActive = event.checked;
      answer.isEdit = true;
    });
    this.positionNumber();
    //
  }
  handleChangeAnswer(topic, answerIndex, event) {
    // ans.isEdit = true
    console.log(topic, answerIndex, event);

    topic.questions.forEach((question) => {
      question.answers[answerIndex].isEdit = true;
      question.answers[answerIndex].isActive = event.checked;
    });
    console.log(topic.questions);
    this.positionNumber();
  }

  checkService() {
    let removeValue = this.topicService.filter(x => !this.serviceGroup.includes(x));


    if (removeValue.length) {
      console.log(removeValue);

      swal({
        title: "Are you sure?",
        text: removeValue[0].serviceName + " service is used by Topic. If you close this service, it will be deleted from the newly created Topic and disabled from Topic that has already been sent.",
        showCancelButton: true,
        confirmButtonText: "Close",
        confirmButtonColor: "#ffa51f",
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          this.oldServiceGroup = [];
          this.serviceGroup.forEach(s => {
            this.oldServiceGroup.push(s);
          });

          this.form.topics.forEach(topic => {
            if ((!topic.id || !this.isSend) && (removeValue[0].id == topic.serviceId)) {
              topic.serviceId = null;
              topic.isEdit = true;
              this.checkTopicService();
            }
            else if ((topic.id || this.isSend) && (removeValue[0].id == topic.serviceId)) {
              var event = { originalEvent: PointerEvent, checked: false }
              this.changeActiveTopic(event, topic);
            }

          });
          console.log(this.form);
        }
        else {
          this.serviceGroup = [];
          this.oldServiceGroup.forEach(s => {
            this.serviceGroup.push(s);
          });
        }
      });
    }
    else {
      this.oldServiceGroup = [];
      this.serviceGroup.forEach(s => {
        this.oldServiceGroup.push(s);
      });
    }

    if (this.form.id) {
      this.form.isEdit = true;
    }
  }
}
