// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command


import env from './.env';

const client_id = 'qm.yamatothai.com-yutsys-qm-frontend'
// const client_id = location.hostname + '-yutsys-backoffice-frontend';

const host = {
  local: 'https://localhost',
  // public: 'http://192.168.110.2'
  public: 'https://survey.yamatothai.com/backend'
};

export const environment = {
  production: false,
  serverUrl: '/api',
  version: env.npm_package_version + '-dev',
  // identityUrl: host.public + ':50001',
  // qm: host.public + ':50201/api',
  //cs: host.local + ':7243/api',
  cs: host.public + '/api',
  identityUrl: 'https://login.yamatothai.com',
  qm: host.public + '/backend/api',
  userUrl: host.public + ':50006',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  identityConfigs: {
    authorizeEndpoint: '/connect/authorize?',
    projectId: 'f1354fef-6407-423c-b6e0-58a3f0f08ad0',
    queryParams: {
      response_type: encodeURI('id_token token'),
      client_id: encodeURI(client_id),
      redirect_uri: encodeURI(location.origin),
      post_logout_redirect_uri: encodeURI(location.origin),
      scope: encodeURI('openid profile qm wmsreceiving'),
      // scope: encodeURI('openid profile backoffice wmsreceiving'),
      nonce: '',
      state: ''
    },
    authParam: {
      client_id: encodeURI(client_id),
      client_secret: 'qm1234',
      scope: 'openid profile yutsys qm wmsreceiving',
      // client_secret: 'wms1234',
      // scope: 'openid profile backoffice wmsreceiving',
      grant_type: 'password',
    },
  },
  encryptConfigs: {
    enable: true,
    pwd: "OCYzM2xpZkxJSDNqZmcqRWY="
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
