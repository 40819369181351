import { NgModule } from '@angular/core';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import {ListboxModule} from 'primeng/listbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DialogModule} from 'primeng/dialog';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ButtonModule} from 'primeng/button';
import {MultiSelectModule} from 'primeng/multiselect';
import {CalendarModule} from 'primeng/calendar';
import {FieldsetModule} from 'primeng/fieldset';
import {BlockUIModule} from 'primeng/blockui';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextareaModule} from 'primeng/inputtextarea';


@NgModule({
  imports: [
    TableModule,
    ToastModule,
    CardModule,
    SidebarModule,
    TabViewModule,
    ListboxModule,
    SelectButtonModule,
    DialogModule,
    DynamicDialogModule,
    ButtonModule,
    MultiSelectModule,
    CalendarModule,
    FieldsetModule,
    BlockUIModule,
    ConfirmDialogModule,
    CheckboxModule,
    DropdownModule,
    MatAutocompleteModule,
    RadioButtonModule,
    InputSwitchModule,
    InputTextareaModule,
  ],
  exports: [
    TableModule,
    ToastModule,
    CardModule,
    SidebarModule,
    TabViewModule,
    ListboxModule,
    SelectButtonModule,
    DialogModule,
    DynamicDialogModule,
    ButtonModule,
    MultiSelectModule,
    CalendarModule,
    FieldsetModule,
    BlockUIModule,
    ConfirmDialogModule,
    CheckboxModule,
    DropdownModule,
    MatAutocompleteModule,
    RadioButtonModule,
    InputSwitchModule,
    InputTextareaModule,
    
    
  ]
})
export class PrimengModule { }
