import { COMMA, E, ENTER, F, U, X } from "@angular/cdk/keycodes";
import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import {
  CustomerGroup,
  Form,
  MoreEmail,
  SetSendEmail,
} from "app/shared/model/design-form-question";
import { FormQuestion, MapDataForSend } from "app/shared/model/form-question";
import { CustomerSurveyService } from "app/shared/service/customer-survey-service";
import { QualityManagementService } from "app/shared/service/quality-management-service";
import { count, log } from "console";
import { youtubeAnalytics } from "googleapis/build/src/apis/youtubeAnalytics";
import { ConfirmationService, MessageService } from "primeng/api";
import swal from "sweetalert2";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { isBuffer } from "util";
import { MatDatepicker } from "@angular/material/datepicker";


@Component({
  selector: "app-mergeform",
  templateUrl: "./mergeform.component.html",
  styleUrls: ["./mergeform.component.css"],
  providers: [MessageService, ConfirmationService, DatePipe],
})
export class MergeformComponent implements OnInit, AfterViewChecked  {
  allCustomer = [];
  allPersonal = [];
  sendDate: any;
  reSendDay: number;
  displayBasic: boolean;
  selectable = true;
  removable = true;
  addOnBlur = true;
  itemsCustomer: any[];
  itemsPersonal: any[];
  itemsCustomerByEmail: any[];
  forms: any[];
  allForms: any[];
  customEmails: any[];

  // group: any[];

  customer: string[] = [];
  personal: string[] = [];

  groupName: string;
  customerGroups: any[] = [];
  customerGroupsSelect: any[] = [];
  customerGroupsNum: number = null;
  enabledAdd: boolean = false;
  enabledAddSetEmail: boolean = false;

  selectedCustomerGroup: number;
  selectedForms: number;
  selectedCustomEmail: number = 1;
  blocked: boolean = true;

  moreEmails: string[] = [];
  moreEmailCtrl = new FormControl("");
  @ViewChild("moreEmailInput") moreEmailInput: ElementRef<HTMLInputElement>;
  filteredMoreEmails: Observable<string[]>;
  moreEmailBase: any[] = [];
  groupmail = [];
  newMoreEmail: string[] = [];

  setSendEmails: any;
  setSendEmailId: number = 0;
  customerEmailType: any = "S";
  sendCount: number = 0;

  enableCsGp: boolean;
  disableCsGp: boolean;

  selectedValuesCsGp: string[] = ['true', 'false'];
  selectedValuesSsEm: string[] = ['true', 'false'];
  customerGroupSwich: any[] = [];
  setSendEmailSwich: any[] = [];
  disableSetEmail:boolean = false;
  minDate: Date = new Date(Date.now());
  // disabledSaveGroup:boolean = true;
  disabledSaveSetSend:boolean = true;

  listSelected: any[] = [];

  isInvoice30day:boolean = false;
  selectedCustomer: any = null; 
  @ViewChild('picker') datePicker: MatDatepicker<any>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];


  
  
  rowsPerPageOptions: number[] = [5, 10, 20, 50, 100]; 
  rowsEmailList: number = 10; 
  rowsCs: number = 10;
  rowsSe: number = 10; 



  constructor(
    public router: Router,
    // private qualityManagementService: QualityManagementService,
    private customerSurveyService: CustomerSurveyService,
    private messageService: MessageService,
    public datepipe: DatePipe,
    private renderer: Renderer2
  ) {
    this.minDate = new Date();
    this.filteredMoreEmails = this.moreEmailCtrl.valueChanges.pipe(
      startWith(null),
      map((moreEmail: string | null) =>
        moreEmail ? this._filter(moreEmail) : this.moreEmails.slice()
      )
    );
  }

  private _filter(value: any): string[] {
    // 
    const filterValue = value.toLowerCase();

    return this.moreEmails.filter((moreEmail) =>
      moreEmail.toLowerCase().includes(filterValue)
    );
  }

  ngOnInit(): void {
    this.startData();
  }

  // ngAfterViewInit() {
  //   this.datePicker.openedStream.subscribe(() => {
  //     this.setDatePickerZIndex();
  //   });
  // }

  ngAfterViewChecked() {
    this.setDatePickerZIndex();
  }

  private setDatePickerZIndex() {
    const overlayContainer = document.querySelector('.cdk-overlay-container');
    if (overlayContainer) {
      this.renderer.setStyle(overlayContainer, 'z-index', '100000000');
    }
  }
  startData() {
    // this.blocked = true;
    this.getCustomer();
    this.getPersonal();
    this.getSetSendEmailList().subscribe(r => {
      this.getGroupCustomer();
    });
    this.getListForm();
    this.displayBasic = false;
    
    // 
  }
  getGroupCustomer() {
    // 
    this.customerGroupsSelect = [];
    this.customerSurveyService.getCustomerGroupList().subscribe((res: any) => {
      res.forEach((customer) => {

        if (customer.customerIds.trim() !== '') {
          customer.customerIds = customer.customerIds.split(",");
        }
       
        //console.log(customer.customerIds);
        customer.personalIds = customer.personalIds.split(",");
        customer.moreEmails = customer.moreEmails.split(",");
        this.customerGroupSwich.push(customer);
      });
      this.customerGroups = res;
      this.customerGroups.forEach((e) => {
        // 
        e.isDelete = true;
        if (this.setSendEmails.find((s) => s.GroupCustomerId == e.id)) {
          e.isDelete = false;
        }
      });
      res.forEach((x) => {
        if(x.isActive == true){
          this.customerGroupsSelect.push({ label:x.id + " : " + x.sendName, value: x.id });
        }
      });

      this.customerSurveyService.getCustomEmailList().subscribe((res: any) => {
       
          if (res) {
            this.customEmails = [];
            res.forEach((x) => {
              this.customEmails.push({ label:x.id + " : " + x.templateName, value: x.id,isActive: x.isActive });
    
            });
          }
        
        this.blocked = false;
      });



      
    });
  }

  getCustomer() {
    this.customerSurveyService.getCustomerList().subscribe((res: any) => {
      this.allCustomer = res;
      //
      this.itemsCustomer = [];
      
      this.itemsCustomerByEmail = [];

      this.allCustomer.forEach((x) => {
        this.itemsCustomer.push({ label:x.CustomerCode + " : " + x.CustomerName, value: x.CustomerCode });


        if (x.E_Mail) {
          let emails = x.E_Mail.split(/[;,/]/);
          //console.log(emails);

          if(emails)
          {
            emails.forEach((email)=> {
              //console.log(email);
              this.itemsCustomerByEmail.push({ label:x.CustomerCode + " | " + x.CustomerName + " [Sale] " + " | "  + email, value: email});

            });
          }

          
        }
        
        if (x.Accounting_E_Mail) {
          let emails = x.Accounting_E_Mail.split(/[;,/]/);
          //console.log(emails);

          if(emails)
          {
            emails.forEach((email)=> {
              //console.log(email);
              this.itemsCustomerByEmail.push({ label:x.CustomerCode + " | " + x.CustomerName + " [Account] " + " | "  + email, value: email});

            });
          }

          
        }
      
      });
      console.log(this.itemsCustomerByEmail);
    });
  }

  getPersonal() {
    this.customerSurveyService.getPersonalList().subscribe((res: any) => {
      //

      this.allPersonal = res;
      this.itemsPersonal = [];
      this.allPersonal.forEach((x) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (x.Email.match(mailformat)) {
          this.itemsPersonal.push({ label: x.EmpCode + " : " + x.NameEn + " " + x.SurnameEn, value: x.EmpCode });
        }
      });
    });

  }

  getListForm() {
    this.customerSurveyService.getFormList().subscribe((res: any) => {
      
      
      if (res) {
        this.forms = [];
        res.forEach((x) => {
          this.forms.push({ label:x.id + " : " +  x.formName, value: x.id,isActive: x.isActive });

        });
      }
    });

    this.customerSurveyService.getFormListForPage().subscribe((res: any) => {
      
      
      if (res) {
        this.allForms = [];
        res.forEach((x) => {
          this.allForms.push({ label: x.formName, value: x.id,isActive: x.isActive });
          
        });
      }
    });
  }

  emailFormatChk() {
    var mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (this.groupmail[this.groupmail.length - 1]) {
      if (!this.groupmail[this.groupmail.length - 1].name.match(mailformat)) {
        this.groupmail.splice(-1);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    //  
    //duplicate data
    const mailDuplicate = this.groupmail.find(
      (x) => x.name == event.option.viewValue
    );
    //console.log(mailDuplicate);
    if (!mailDuplicate) {
      this.groupmail.push({
        name: event.option.viewValue.trim().toLowerCase(),
      });
    }
    this.moreEmailInput.nativeElement.value = "";
    this.moreEmailCtrl.setValue(null);
    this.emailFormatChk();
    this.showAllEmailGroup()
  }

  onDropdownChange(event: any): void {
    if (event) {
      this.selectedCustomerByEmail(event);
      setTimeout(() => {
        this.selectedCustomer = null; // รีเซ็ต dropdown หลังจากเรียกฟังก์ชัน
      }, 0);
    }
  }
  
  selectedCustomerByEmail(event: any): void {
    //  
    //duplicate data
    //console.log(event,this.groupmail);
    const mailDuplicate = this.groupmail.find(
      (x) => x.name.trim().toLowerCase() == event.trim().toLowerCase()
    );
    //console.log(mailDuplicate);
    if (!mailDuplicate) {
      this.groupmail.push({
        name: event.trim().toLowerCase(),
      });
    }
    this.moreEmailInput.nativeElement.value = "";
    this.moreEmailCtrl.setValue(null);
    this.emailFormatChk();
    this.showAllEmailGroup()
    
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    //

    
    if ((value || "").trim()) {
      const mailDuplicate = this.groupmail.find(
        (x) => x.name.trim().toLowerCase() == value.trim().toLowerCase()
      );
  
      if (!mailDuplicate) {
        this.groupmail.push({ name: value.trim().toLowerCase() });
        this.emailFormatChk();
      }
      
    }

    if (input) {
      input.value = "";
    }
    //
    this.showAllEmailGroup()
  }

  remove(email: any): void {
    const index = this.groupmail.indexOf(email);

    if (index >= 0) {
      this.groupmail.splice(index, 1);
    }
  }

  confrimSaveCustomerGroup() {
    if(this.groupName){
      swal({
        title: "Are you sure?",
        text: "You will Save Customer Group !",
        showCancelButton: true,
        confirmButtonText: "Save",
        confirmButtonColor: "#ffa51f",
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          this.saveCustomerGroup();
        }
      });
    }
    else{
      swal({
        title: "Alert",
        text: "Please enter a group name.",
        confirmButtonText: "OK",
        confirmButtonColor: "#ffa51f",
        allowOutsideClick: false
      });
    }


    
  }

  saveCustomerGroup() {
    let customerGroupSend: any = {};
    //
    if (this.customerGroupsNum == null) {
      let addCustomerGroup: {
        id: number;
        customerIds: string;
        personalIds: string;
        moreEmails: string;
        sendName: string;
        newMoreEmail: string[];
        isActive: boolean;
      } = {
        id: 0,
        customerIds: "",
        personalIds: "",
        moreEmails: "",
        sendName: this.groupName.trim(),
        newMoreEmail: this.newMoreEmail,
        isActive: true
      };
      this.customer.forEach((cs, index) => {
        if (index === this.customer.length - 1) {
          //
            //this.customer,
            //"เข้า",
           // this.customer.length,
            //index,
            //addCustomerGroup
          //);
          addCustomerGroup.customerIds += cs;
        } else {
          addCustomerGroup.customerIds += cs + ",";
        }
      });

      this.personal.forEach((ps, index) => {
        if (index === this.personal.length - 1) {
          addCustomerGroup.personalIds += ps;
        } else {
          addCustomerGroup.personalIds += ps + ",";
        }
      });

      this.groupmail.forEach((em, index) => {
        var moreEmailId = this.moreEmailBase.find((e) => e.email == em.name);
        if (moreEmailId) {
          if (index === this.groupmail.length - 1) {
            addCustomerGroup.moreEmails += moreEmailId.id;
          } else {
            addCustomerGroup.moreEmails += moreEmailId.id + ",";
          }
        } else {
          //
          this.newMoreEmail.push(em.name);
        }
      });
      //
      customerGroupSend = addCustomerGroup;
    } else {
      this.customerGroups[this.customerGroupsNum].sendName =
        this.groupName.trim();
      this.customerGroups[this.customerGroupsNum].customerIds = "";
      this.customerGroups[this.customerGroupsNum].personalIds = "";
      this.customerGroups[this.customerGroupsNum].moreEmails = "";

      this.customer.forEach((cs, index) => {
        if (index === this.customer.length - 1) {
          //
          this.customerGroups[this.customerGroupsNum].customerIds += cs;
        } else {
          this.customerGroups[this.customerGroupsNum].customerIds += cs + ",";
        }
      });

      this.personal.forEach((ps, index) => {
        if (index === this.personal.length - 1) {
          this.customerGroups[this.customerGroupsNum].personalIds += ps;
        } else {
          this.customerGroups[this.customerGroupsNum].personalIds += ps + ",";
        }
      });

      this.groupmail.forEach((em, index) => {
        var moreEmailId = this.moreEmailBase.find((e) => e.email == em.name);
        if (moreEmailId) {
          if (index === this.groupmail.length - 1) {
            this.customerGroups[this.customerGroupsNum].moreEmails +=
              moreEmailId.id;
          } else {
            this.customerGroups[this.customerGroupsNum].moreEmails +=
              moreEmailId.id + ",";
          }
        } else {
          this.newMoreEmail.push(em.name);
        }
      });

      customerGroupSend = this.customerGroups[this.customerGroupsNum];
      customerGroupSend["newMoreEmail"] = this.newMoreEmail;
      //
    }
    customerGroupSend["customerType"] = this.customerEmailType;
    //
    //

    this.saveCustomerGroupFunction(customerGroupSend).subscribe(r => {
      if (r == true) {
        //
        //
        this.showSuccess();
        this.getGroupCustomer();
      }
    });

  }

  saveCustomerGroupFunction(customerGroupSend) {
    const x = new Observable((obs) => {
      this.customerSurveyService.saveCustomerGroup(customerGroupSend).subscribe(
        (res: any) => {

          //
          let b = this.customerGroups.find((x) => x.id == res.id);
          if (b) {
           // 
            if (b.isActive != res.isActive) {
              b.isActive = res.isActive;
            }
          }
          else {
            //
          }

          this.customerGroups.sort((a, b) => {
            return b.isActive - a.isActive;
          });

          this.disableManageGroup();
          this.enabledAdd = false;
          obs.next(true);
        },
        (err: any) => {
          this.showWarn();
        }
      );
    });
    return x;
  }

  openAddCustomerGroup() {
    this.enabledAdd = true;
    this.customerSurveyService.getMoreEmailList().subscribe((res: any) => {
      this.moreEmailBase = res;
      for (var i = 0; i < this.moreEmailBase.length; i++) {
        this.moreEmails.push(this.moreEmailBase[i].email);
      }

      this.listSelected = [];
    });
  }

  EditCustomerGroup(customerData: any, index: number) {
    this.enabledAdd = true;
    this.groupName = this.customerGroups[index].sendName.trim();
    this.customerGroupsNum = index;
    if (this.customerGroups[index].customerIds.length) {
      this.customerGroups[index].customerIds.forEach((customerId) => {
        //console.log(customerId,this.customerGroups[index].customerIds);
        this.customer.push(customerId.trim());
      });
    }

    if (this.customerGroups[index].personalIds.length) {
      this.customerGroups[index].personalIds.forEach((personalId) => {
        this.personal.push(personalId.trim());
      });
    }

    this.customerSurveyService.getMoreEmailList().subscribe((res: any) => {
      this.moreEmailBase = res;

      for (var i = 0; i < this.moreEmailBase.length; i++) {
        this.moreEmails.push(this.moreEmailBase[i].email);
      }
      //
        //this.customerGroups[index].moreEmails.length,
        //this.customerGroups[index].moreEmails
     // );

      if (this.customerGroups[index].moreEmails.length) {
        this.customerGroups[index].moreEmails.forEach((emailId, i) => {
          //
          var emailStr = this.moreEmailBase.find((e) => e.id == emailId);
          //

          if (emailStr) {
            this.groupmail.push({ name: emailStr.email.trim() });
          }
          this.showAllEmailGroup();
        });
      }
    });
    //console.log(this.customer.length,this.customer);
  }
  disableManageGroup() {
    this.groupName = "";
    this.customer = [];
    this.personal = [];
    this.groupmail = [];
    this.customerGroupsNum = null;
    this.moreEmails = [];
    this.enabledAdd = false;
    // this.disabledSaveGroup = true;
  }

  //-------------------------------------------------setsendemail-------------------------------------------------------------------
  getSetSendEmailList() {
    const x = new Observable((obs) => {
      //

      this.customerSurveyService.getSetSendEmailList().subscribe((res: any) => {
        //
        //console.log(res);
        this.setSendEmails = [];
        res.forEach((x) => {
          this.setSendEmails.push({
            SetSendEmailId: x.SetSendEmailId,
            CustomerGroupName: x.CustomerGroupName,
            GroupCustomerId: x.GroupCustomerId,
            FormId: x.FormId,
            FormName: x.FormName,
            CustomEmailId: x.CustomEmailId,
            TemplateName: x.TemplateName,
            SendDate: x.SendDate,
            SendCount: x.SendCount,
            ReSendDay: x.ReSendDay,
            IsActive: x.IsActive,
            IsInvoice30day: x.IsInvoice30day
          });
          this.setSendEmailSwich.push({
            SetSendEmailId: x.SetSendEmailId,
            CustomerGroupName: x.CustomerGroupName,
            GroupCustomerId: x.GroupCustomerId,
            FormId: x.FormId,
            FormName: x.FormName,
            CustomEmailId: x.CustomEmailId,
            TemplateName: x.TemplateName,
            SendDate: x.SendDate,
            SendCount: x.SendCount,
            ReSendDay: x.ReSendDay,
            IsActive: x.IsActive,
            IsInvoice30day: x.IsInvoice30day
          });
        });
        obs.next(true);
      });
    });
    return x;
  }

  openAddSetSendEmail() {
    this.enabledAddSetEmail = true;
  }

  disableManageSetSendEmail() {
    this.setSendEmailId = 0;
    this.selectedCustomerGroup = null;
    this.selectedForms = null;
    this.sendDate = null;
    this.reSendDay = null;
    this.isInvoice30day = false;
    this.enabledAddSetEmail = false;
    this.disableSetEmail = false;
    this.disabledSaveSetSend = true;
  }

  confrimSaveSetSendEmail() {
    
    
    if(this.selectedCustomerGroup && this.selectedForms && this.sendDate ){
      swal({
        title: "Are you sure?",
        text: "You will Save Set Send Email !",
        showCancelButton: true,
        confirmButtonText: "Save",
        confirmButtonColor: "#ffa51f",
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          this.saveSetSendEmail();
        }
      });

    }
    else{
      swal({
        title: "Alert",
        text: "Please fill in the missing information.",
        confirmButtonText: "OK",
        confirmButtonColor: "#ffa51f",
        allowOutsideClick: false
      })
    }
    
  }
  EditSetSendEmail(setSendEmailData: any, index: number) {
    this.openAddSetSendEmail();
   // 
    
    if(setSendEmailData.SendCount > 0){
      this.disableSetEmail = true;
    }
    this.setSendEmailId = setSendEmailData.SetSendEmailId;
    this.selectedCustomerGroup = setSendEmailData.GroupCustomerId;
    this.selectedForms = setSendEmailData.FormId;
    this.selectedCustomEmail = setSendEmailData.CustomEmailId;
    this.sendDate = this.datepipe.transform(
      setSendEmailData.SendDate,
      "yyyy-MM-dd"
    );
    this.reSendDay = setSendEmailData.ReSendDay;
    this.sendCount = setSendEmailData.SendCount;
    this.isInvoice30day = setSendEmailData.IsInvoice30day;
    //console.log(setSendEmailData.IsInvoice30day);
    // 
    //   this.setSendEmailId,
    //   this.selectedCustomerGroup,
    //   this.selectedForms,
    //   this.sendDate,
    //   this.reSendDay
    // );
  }

  saveSetSendEmail() {
    let setSendEmail: SetSendEmail = {
      id: this.setSendEmailId,
      groupCustomerId: this.selectedCustomerGroup,
      formId: this.selectedForms,
      customEmailId: this.selectedCustomEmail,
      sendDate: this.datepipe.transform(
        this.sendDate,
        "yyyy-MM-dd"
      ),
      reSendDay: this.reSendDay,
      isActive: true,
      sendCount: this.sendCount,
      isInvoice30day: this.isInvoice30day,
    };

    if(!setSendEmail.reSendDay)
    {
      setSendEmail.reSendDay = 0;
    }

    const setEmailDuplicate = this.setSendEmails.find(
      (x) => x.GroupCustomerId == setSendEmail.groupCustomerId && x.FormId == setSendEmail.formId
    );
   // 
    if (!setSendEmail.id) {
      if (setEmailDuplicate) {
        swal({
          title: "Duplicate",
          text: "This customer group and form are already matched in another set send email",
          confirmButtonText: "Change",
          confirmButtonColor: "#ffa51f",
          allowOutsideClick: false
        }).then((result) => {
        });
      }
      else {
        this.saveSetSendEmailFuction(setSendEmail).subscribe(r => {
          this.startData();
          this.showSuccess();
        });
      }
    }
    else {
      this.saveSetSendEmailFuction(setSendEmail).subscribe(r => {
        this.startData();
        this.showSuccess();
      });
    }

  }

  saveSetSendEmailFuction(setSendEmailData) {
    const x = new Observable((obs) => {
      //
      this.customerSurveyService.saveSetSendEmail(setSendEmailData).subscribe(
        (res: any) => {
          //
          let b = this.setSendEmails.find((x) => x.Id == res.id);
          if (b) {
            //
            if (b.IsActive != res.isActive) {
              b.IsActive = res.isActive;
            }
          }

          this.setSendEmails.sort((a, b) => {
            return b.IsActive - a.IsActive;
          });
          this.disableManageSetSendEmail();
          // this.getSetSendEmailList();
          obs.next(true);
        },
        (err: any) => {
          this.showWarn();
        }
      );
    });
    return x;
  }

  showSuccess() {
    this.messageService.add({
      severity: "success",
      summary: "Service Message",
      detail: "Save Successful",
    });
  }

  showWarn() {
    this.messageService.add({
      severity: "warn",
      summary: "Service Message",
      detail: "Save failed",
    });
  }

  view() {
    
  }

  selectSaleCustomer() {
    this.customer = [];
    this.itemsCustomer = [];
    this.allCustomer.forEach((x) => {
      this.itemsCustomer.push({ label: x.CustomerName, value: x.CustomerCode });
    });
  }

  selectAccountingCustomer() {
    this.customer = [];
    this.itemsCustomer = [];
    this.allCustomer.forEach((x) => {
      if (x.Accounting_E_Mail) {
        this.itemsCustomer.push({ label: x.CustomerName, value: x.CustomerCode });
      }
    });
  }

  handleChangeCustomerGroup(event, cusData, index) {
    let customerGroup: {
      id: number;
      customerIds: string;
      personalIds: string;
      moreEmails: string;
      sendName: string;
      isActive: boolean;
      customerType: String;
      newMoreEmail: string[];
    } = {
      id: cusData.id,
      customerIds: "",
      personalIds: "",
      moreEmails: "",
      sendName: cusData.sendName.trim(),
      isActive: cusData.isActive,
      customerType: cusData.customerType,
      newMoreEmail: this.newMoreEmail,
    };

    console.log(index);
    if(cusData.customerIds.length > 0)
    {
      cusData.customerIds.forEach((cs, index) => {
        //console.log(index);
        
        if (index === cusData.customerIds.length - 1) {
          //
          customerGroup.customerIds += cs;
        } else {
          customerGroup.customerIds += cs + ",";
        }
      });
    }
    
    if(cusData.personalIds.length > 0)
    {
      cusData.personalIds.forEach((ps, index) => {
        if (index === cusData.personalIds.length - 1) {
          customerGroup.personalIds += ps;
        } else {
          customerGroup.personalIds += ps + ",";
        }
      });
    }
   
    if(cusData.moreEmails.length > 0)
    {
      cusData.moreEmails.forEach((em, index) => {
        if (index === cusData.moreEmails.length - 1) {
          customerGroup.moreEmails += em;
        } else {
          customerGroup.moreEmails += em + ",";
        }
      });
    }
  

    var setSendEmailDisable = [];
    if (event.checked == false) {
      this.setSendEmails.forEach((setData) => {
        //
        if (setData.GroupCustomerId == customerGroup.id) {
          setData.Id = setData.SetSendEmailId;
          setData.IsActive = false;
          setSendEmailDisable.push(setData);
        }
      });

      if (setSendEmailDisable.length > 0) {
        swal({
          title: "Are you sure?",
          text: "If you disable this customer group,set send email that are active in this customer group will also be disable",
          showCancelButton: true,
          confirmButtonText: "Disable",
          confirmButtonColor: "#ffa51f",
          allowOutsideClick: false
        }).then((result) => {
         // 
          if (result.value) {
            this.customerSurveyService
              .saveSetSendEmailsMultiple(setSendEmailDisable)
              .subscribe((r: any) => {
                this.getSetSendEmailList();
                this.saveCustomerGroupFunction(customerGroup).subscribe(r => {
                  this.messageService.add({
                    severity: "warn",
                    summary: "Service Message",
                    detail: "Disable " + customerGroup.sendName,
                  });
                });
              });
          } else {
            //
            this.setSendEmails.forEach((setData) => {
              //
              if (setData.GroupCustomerId == customerGroup.id) {
                setData.IsActive = true;
              }
            });
            this.customerGroups[index].isActive = true;
          }
        });
      } else {
        this.saveCustomerGroupFunction(customerGroup).subscribe(r => {
          this.messageService.add({
            severity: "warn",
            summary: "Service Message",
            detail: "Disable " + customerGroup.sendName,
          });
        });
      }
    } else {
      this.saveCustomerGroupFunction(customerGroup).subscribe(r => {
        this.messageService.add({
          severity: "success",
          summary: "Service Message",
          detail: "Enable " + customerGroup.sendName,
        });
      });
    }
  }

  handleChangeSetSendEmail(event, setData, i) {
    // 
    // 
    // 
    // 

    var customerEnable = this.customerGroups.find(x => x.id == setData.GroupCustomerId);
    
    
    var formEnable = this.allForms.find(x => x.value == setData.FormId);
    if (customerEnable && customerEnable.isActive == false) {

      swal({
        title: "Group is Disabled",
        text: "You must enable the group '" + customerEnable.sendName + "' before to enable this set send email.",
        confirmButtonText: "Ok",
        confirmButtonColor: "#ffa51f",
        allowOutsideClick: false
      }).then((result) => {
        //
        if (result.value) {
          this.setSendEmails[i].IsActive = false;
        }
      });
    }
    else if(formEnable && formEnable.isActive == false ){
      swal({
        title: "Form is Disabled",
        text: "You must enable the form '" + formEnable.label + "' before to enable this set send email.",
        confirmButtonText: "Ok",
        confirmButtonColor: "#ffa51f",
        allowOutsideClick: false
      }).then((result) => {
        //
        if (result.value) {
          this.setSendEmails[i].IsActive = false;
        }
      });
    }
    else {
      if (event) {
        setData.Id = setData.SetSendEmailId;
       // 
        this.saveSetSendEmailFuction(setData).subscribe((res: any) => {
          //
        })
        //
        if (event.checked == true) {
          this.messageService.add({
            severity: "success",
            summary: "Service Message",
            detail: "Enable " + "Set" + "[Group: " + setData.CustomerGroupName + ", Form: " + setData.FormName + "]",
          });
        }
        else {
          this.messageService.add({
            severity: "warn",
            summary: "Service Message",
            detail: "Disable " + "Set" + "[Group: " + setData.CustomerGroupName + ", Form: " + setData.FormName + "]",
          });
        }
      }
    }
  }



  getBoolean(value) {
    switch (value) {
      case true:
      case "true":
        return true;
      default:
        return false;
    }
  }

  searchSelectionCustomerGroup() {
    this.customerGroups = this.customerGroupSwich.filter((x) =>
      this.selectedValuesCsGp.some((s) => this.getBoolean(s) === x.isActive)
    );
  }

  searchSelectionSetSendEmail() {
    this.setSendEmails = this.setSendEmailSwich.filter((x) =>
      this.selectedValuesSsEm.some((s) => this.getBoolean(s) === x.IsActive)
    );
  }



  DeleteCustomerGroup(cus) {
    swal({
      title: "Are you sure?",
      text: "You will delete this customer group?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#ffa51f",
      allowOutsideClick: false
    }).then((result) => {
      //
      if (result.value) {
        this.customerSurveyService
          .deleteCustomerGroupById(cus.id)
          .subscribe((r: any) => {
            this.getGroupCustomer();
            this.messageService.add({
              severity: "error",
              summary: "Service Message",
              detail: "Delete " + cus.sendName,
            });
          },
            (err: any) => {
              this.showWarn();
            });
      }
    });
  }

  DeleteSetSendEmail(set) {
    swal({
      title: "Are you sure?",
      text: "You will delete this set send email?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#ffa51f",
      allowOutsideClick: false
    }).then((result) => {
      //
      if (result.value) {
        this.customerSurveyService
          .deleteSetSendEmailsById(set.SetSendEmailId)
          .subscribe((r: any) => {
            this.getSetSendEmailList().subscribe(r => {
              this.messageService.add({
                severity: "error",
                summary: "Service Message",
                detail: "Delete " + "Set" + "[Group: " + set.CustomerGroupName + ", Form: " + set.FormName + "]",
              });
            });

          },
            (err: any) => {
              this.showWarn();
            });
      }
    });
  }

  // checkSaveGp(){
  //   if(this.groupName){
  //     this.disabledSaveGroup = false;
  //   }
  //   else{
  //     this.disabledSaveGroup = true;
  //   }
  // }



  showAllEmailGroup()
  {
    
    this.listSelected = [];
    if(this.customer.length > 0)
    {
      
      const filter = this.itemsCustomer.filter(x => this.customer.includes(x.value));

      filter.forEach(email => {
        this.listSelected.push({sendName:email.label});
      });
    }

    if(this.personal.length > 0)
    {
      const filter = this.itemsPersonal.filter(x => this.personal.includes(x.value));

      filter.forEach(email => {
        this.listSelected.push({sendName:email.label});
      });
    }

    if(this.groupmail.length > 0)
    {
      this.groupmail.forEach(email => {
        this.listSelected.push({sendName:email.name});
      });
     
    }
    //console.log(this.groupmail,this.filteredMoreEmails);
  }

  checkSaveSs(){
    
    
    if((this.selectedCustomerGroup || this.isInvoice30day) && this.selectedForms && this.sendDate){
      this.disabledSaveSetSend = false;
    }
    else{
      this.disabledSaveSetSend = true;
    }
  }

  exportHistory(id)
  {
    this.customerSurveyService.getExportHistory(id);
  }

   exportCusAnswer(id)
  {
    this.customerSurveyService.getExportCusAnswer(id);
  }
}
