import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class QualityManagementService {
    constructor(private http: HttpClient) { }

    saveData(body: any) {
        return this.http.auth().post(environment.qm + '/qm/saveinterface', body);
    }
    listsaveData(body: any) {
        return this.http.auth().post(environment.qm + '/qm/listsaveinterface', body);
    }
    listsaveClearOld(body: any) {
        return this.http.auth().post(environment.qm + '/qm/saveinterfacesingle', body);
    }
    getOtp(body: any) {
        return this.http.auth().post(environment.qm + '/qm/genOtp', body);
    }
    verifyOtp(res: string) {
        return this.http.get(environment.qm + '/qm/verifyOtp?token=' + res);
    }
    getFormList(table: string) {

        return this.http.auth().get(environment.qm + '/qm/formlist/' + table);
    }
    getFormById(id: any, table: string) {
        return this.http.auth().get(environment.qm + '/qm/formlistId/' + id, { params: { table: table } });
    }

    getFormByIdNoAuth(id: any, table: string) {
        
        return this.http.get(environment.qm + '/qm/formlistIdNoAuth/' + id, { params: { table: table } });
    }
    getDynamic(body: any, table: string) {
        return this.http.auth().post(environment.qm + '/qm/mapDynamic', body, { params: { table: table } });
    }
    getDynamicNoAuth(body: any, table: string) {
        return this.http.auth().post(environment.qm + '/qm/mapDynamicNoAuth', body, { params: { table: table } });
    }
    
    sendMail() {
        return this.http.auth().get(environment.qm + '/qm/sendemail/');
    }

    getLang(lang) {
        return this.http.auth().get(environment.qm + '/lang/' + lang);
    }

    saveLang(body) {
        return this.http.auth().post(environment.qm + '/lang/', body);
    }
    updateTokenNoAuth(body) {
        return this.http.post(environment.qm + '/qm/updateinterfacsingle', body);
    }

    saveNoAuth(body) {
        return this.http.post(environment.qm + '/qm/saveinterfacenoauth', body);
    }
}
