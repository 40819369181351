import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';



import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { SharedModule } from './shared/shared.module';
import { HttpService } from './core/http/http.service';
import { APP_BASE_HREF } from '@angular/common';
import { CoreModule } from './core/core.module';
import { FormlistComponent } from './management/management/design-form-qm/formlist/formlist.component';
import { MergeformComponent } from './management/management/design-form-qm/mergeform/mergeform.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InputRequestorInfoModule } from './management/input-requestor-info/input-requestor-info.module';
import { environment } from 'environments/environment';
import { FormDesignComponent } from './management/management/design-form-qm/form-design/form-design.component';
import { LoginComponent } from './login/login.component';
import { QuestionnaireMComponent } from './management/management/design-form-qm/questionnaire-m/questionnaire-m.component';
import { QuestionnaireComponent } from './management/management/design-form-qm/questionnaire/questionnaire.component';
import { myFilterPipe } from './shared/myFilterPipe';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http.disableApiResponse(), environment.qm + '/lang/', '');
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputRequestorInfoModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    SharedModule,
    AdminLayoutModule,

    CoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    AdminLayoutComponent,
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' },
  {
    provide: HttpClient,
    useClass: HttpService,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
