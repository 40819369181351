import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '../logger.service';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdentityService } from './identity.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private identityService: IdentityService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    
    
    if ((this.router.url === '/' || this.router.url === '/home') && window.location.hash) {
      return this.identityService.AuthorizedCallback();
    }
    return this.isAuthorized();
  }

  private isAuthorized(): Observable<boolean> | Promise<boolean> | boolean {
    return this.identityService.getIsAuthorized().pipe(
      map((isAuthorized: boolean) => {
        const projectId = this.identityService.getProjectId();
        if (isAuthorized) {
          return true;
        }

        this.identityService.Authorize();
        return false;
      })
    );
  }
}
