import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  // { path: '/designform', title: 'Design Form', icon: 'person', class: '' },
  // { path: '/dashboard', title: 'Dashboard', icon: 'person', class: '' },
  { path: '/formlist', title: 'Form List', icon: 'list', class: '' },
  { path: '/formdesign', title: 'form Design', icon: 'content_paste', class: '' },
  { path: '/mergeform', title: 'E-Mail Setting', icon: 'contact_mail', class: '' },
  { path: '/emailcontent', title: 'E-Mail Content', icon: 'mail', class: '' },

  // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
  // { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
  // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  userName: string;

  constructor(private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('userId') == null) {
      this.menuItems = [];
    } else {
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    var userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      this.userName = userData.name + " " + userData.last_name
    }

    // Subscribe to router events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Check if the user is on the /formdesign page
        if (this.router.url.includes('/formdesign')) {
          // Show a confirmation using the alert function
          const confirmNavigation = confirm('Your data may not be saved. Are you sure you want to leave this page?');
          if (!confirmNavigation) {
            // Prevent navigation if the user cancels
            this.router.navigate([this.router.url]);
          }
        }
      }
    });
  }
  
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
  logout() {
    localStorage.clear();
    window.location.reload();
  }
}
