import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../authentication/storage.service';
import { IdentityService } from '../authentication/identity.service';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable(
  {
    providedIn: 'root'
  }
)
export class AuthInterceptor implements HttpInterceptor {
  private token: string;

  constructor(private storage: StorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = this.storage.retrieve('authorizationData');
    
    request = request.clone({
      url: request.url,
      headers: request.headers.append('Authorization', 'Bearer ' + decodeURIComponent(this.token))
    });

    return next.handle(request);
  }
}
