import { Injectable } from '@angular/core';

const APP_PREFIX = 'app-';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storage: any;
  private session: any;

  constructor() {
    this.storage = localStorage; // sessionStorage | localStorage;
    this.session = sessionStorage;
  }

  public retrieve(key: string): any {
    const item = this.storage.getItem(key);

    if (item && item !== 'undefined') {
      return JSON.parse(this.storage.getItem(key));
    }

    return;
  }


  public store(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public clearBy(key: string) {
    this.storage.removeItem(key);
  }

  public clearAll() {
    this.storage.clear();
  }

  public sessionStore(key: string, value: any) {
    this.session.setItem(key, JSON.stringify(value));
  }

  public sessionRetrive(key: any) {
    const item = this.session.getItem(key);

    if (item && item !== 'undefined') {
      return JSON.parse(this.session.getItem(key));
    }

    return;
  }

  public sessionClearBy(key: string) {
    this.session.removeItem(key);
  }
  public clear() {
    this.storage.clear();
}
}
