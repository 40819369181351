// export class FormQuestion {
//   form_id: number = 0;
//   formName: string = "";
//   formtDetail: string = "";
//   groupQuestion: GroupQuestion[] = [];
//   isActive: number = 0;
//   flag: number = 0;
// }
// export class GroupQuestion {
//   groupId: number = 0;
//   groupName: string = "";
//   question: Question[] = [];
//   typeQuestionId: number = 0;
//   flag: number = 0;
// }

// export class Question {
//   qustionId: number;
//   questionName: string;
//   answer: Answer[] = [];
//   isActive: number = 0;
//   flag: number = 0;
// }
// export class Answer {
//   answerId: number;
//   answerName: string;
//   answerPoint: number = 0;
//   isActive: number = 0;
//   flag: number = 0;
// }
// export class typeQuestion {
//   id: number = 0;
//   name: string = "";
// }

export class Form {
  id: number = 0;
  formName: string;
  formDetail: string;
  topics: Topic[];
  isActive: boolean = true;
  serviceIds: string;
  isEdit:boolean;
}
export class Types {
  id: number = 0;
  typeName: string;
  typeDescription: string;
  isActive: boolean = true;
  isEdit:boolean = false;
}

export class Topic {
  id: number = 0;
  formId: number;
  topicName: string;
  topicNumber: number = 0;
  questions: Question[];
  typeId: number;
  typeLock: boolean = false;
  isActive: boolean = true;
  isEdit:boolean = false;
  topicPosition: number = 0;
  serviceId: number;
  serviceName:string;
}

export class Question {
  id: number = 0;
  topicId: number;
  questionName: string;
  questionNumber: number = 0;
  answers: Answer[];
  isActive: boolean = true;
  isEdit:boolean = false;
  rowsNumber:number = 0;
  questionPosition: number = 0;
}

export class Answer {
  id: number = 0;
  questionId: number;
  answerName: string = "";
  answerNumber: number = 0;
  point: number = 1;
  isActive: boolean = true;
  isEdit:boolean = false;
  isAns:boolean = false;
  textAnswer : string = "";
  answerPosition: number = 0;
}

export class SetSendEmail {
  id: number = 0;
  groupCustomerId: number;
  formId: number;
  customEmailId:number;
  sendDate: any;
  reSendDay: number;
  isActive: boolean = true;
  sendCount: number;
  isInvoice30day:boolean;
}
export class CustomerGroup {
  id: number = 0;
  customerIds: any[];
  personalIds: any[];
  moreEmails: string[];
  sendName: string;
  isActive:boolean;
}
export class MoreEmail {
  id: number = 0;
  email: string;
}

